#dashboard-plannings-container {
    background-color: #606E7C;
	border: 0.5px solid #606E7C;
	display: flex;
	flex-direction: column;
    height: 100%;
	width: 180px;
	position: fixed;
	z-index: 0;
	top: 0;
	left: 0;
	overflow-x: hidden;
	padding-top: 60px;
}

#new-planning-container {
    margin-bottom: 7px;
	margin-left: 7px;
}

#dashboard-plannings-list {
	height: 45%;
    overflow-y: auto;
}

#colibri-dashboard-container {
	margin-left: 60px;
	margin-top: 10px;
	margin-bottom: 120px;
}



.colibri-dashboard-container-row {
	width: 100%;
	height: 50vh;
	display: flex;
}

.colibri-dashboard-container-row-large {
	width: 100%;
	height: 70vh;
	display: flex;
}

.colibri-dashboard-big-container {
	height: 90vh;
	width: 100%;
	display: flex;
}

.colibri-dashboard-subcontainer {
	background-color: white;
	margin: 5px;
	padding: 4px;
	border: 0.5px solid #ccc;
	border-radius: 4px;
	flex: 1;
	width: calc(100% - 10px);
}

.colibri-dashboard-subcontainer-no-width {
	background-color: white;
	margin: 5px;
	padding: 4px;
	border: 0.5px solid #ccc;
	border-radius: 4px;
}

.subcontainer-column-full-height {
	height: calc(100% - 15px);
}

.subcontainer-column-half-height {
	height:calc(50% - 10px);
}

.colibri-dashboard-container-column-large {
	height: 100%;
	width: 55%;
}

.colibri-dashboard-container-column-narrow {
	height: 100%;
	width: 45%;
}

.colibri-subcontainer-body {
	height: calc(100% - 37px);
	overflow-y: auto;
	overflow-x: auto;
}

.colibri-subcontainer-body-with-footer {
	overflow-y: auto;
	overflow-x: auto;
}

.colibri-subcontainer-footer {
	margin-top: 7px;
}

.colibri-subcontainer-header {
	border-bottom: 0.5px solid #ccc;
	padding-bottom: 4px;
	height: 33px;
}

.unseen-activity-count {
	font-size: 0.9em;
	color: #4F4F4F;
}

#extended-daily-summary-title-container {
	display: flex;
	margin: 5px;
	margin-top: 30px;
}

.post-clickable:hover {
	cursor: pointer;
}

.activity_header {
	display: none;
}

@media print {
	#extended-daily-summary-container {
		height: auto !important;
		overflow: visible !important;
	}
}

@media screen and (orientation: portrait) and (min-width: 760px) {
	.colibri-dashboard-big-container {
		height: auto;
		flex-direction: column-reverse;
	}

	.colibri-dashboard-container-column-large {
		width: 100%;
		height: 90vh;
	}

	.colibri-dashboard-container-column-narrow {
		width: 100%;
		height: 45vh;
	}
}