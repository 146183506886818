#contact-form-background {
    padding-top:50px;
    padding-bottom:50px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #e7f6ff;
}

#colibri-contact-form-container {
    width: 100%;
    max-width: 450px;
    background-color: white;
    padding: 20px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

#contact-form-image-container {
    width: 100%;
    max-width: 450px;
    padding:20px;
}

#colibri-contact-form-title {
    color: #1B263B;
    text-align: center;
    font-size: 1.6em;
    margin-bottom: 20px;
}

#call-colibri {
    display: none;
}

@media screen and (orientation: portrait) {

    #colibri-contact-form-container {
        padding: 20px 20px 20px 20px;
    }

    #colibri-contact-form-title {
        font-size: 1.6em;
        margin-bottom: 20px;
    }

}