@import "./activities.css";
@import "./appointments.css";
@import "./admins.css";
@import "./assessments.css";
@import "./care_manager_corporation.css";
@import "./comments.css";
@import "./completion_report.css";
@import "./contact_form.css";
@import "./custom_responsive_home_page.css";
@import "./dashboard.css";
@import "./devise.css";
@import "./forms.css";
@import "./general_phone.css";
@import "./general_tablet.css";
@import "./general.css";
@import "./nurses.css";
@import "./pages.css";
@import "./patients.css";
@import "./plannings.css";
@import "./posts.css";
@import "./provided_services.css";
@import "./reactions.css";
@import "./recurring_appointments.css";
@import "./responsive_desktop.css";
@import "./responsive_smartphone_landscape.css";
@import "./responsive_smartphone_portrait.css";
@import "./responsive_tablet_portrait.css";
@import "./scaffolds.css";
@import "./users.css";
@import "./background_tasks.css";

/*
@import '@fullcalendar/common/main.css';
@import '@fullcalendar/daygrid/main.css';
@import '@fullcalendar/timegrid/main.css';
@import '@fullcalendar/list/main.css';
@import '@fullcalendar/timeline/main.css';
@import '@fullcalendar/resource-timeline/main.css';
*/
@import "./fc_custom.css";

@import "selectize/dist/css/selectize.bootstrap3.css";
