@media screen and (orientation: landscape) and (max-width: 900px), screen and (orientation: portrait) and (max-width: 760px) {
    
    body {
        overflow: hidden;
    }

    .modal-body, .modal-footer {
        padding: 15px !important;
    }

    .smartphone-no-display {
        display: none !important;
    }

    .activity_large_header {
        background-color: #2B384E;
    }
    
    .activity_subheader {
        background-color: #2B384E;
        display: flex;
        height: 35px;
        line-height: 35px;
        color: white;
    }

    .colibri-homepage-subsection-title {
        font-size: 1.9em;
    }
    
	.activity_subtitle {
		font-size: 1.3em;
        color: #4F5D66;
        border-bottom: 0.5px solid rgb(215, 215, 215);
        display:flex;
        justify-content: space-between;
    }
    
    .toggleable_activity {
        display: none;
    }

	.activity_subcontainer {
		padding: 4px;
		margin-top: 5px;
    }
    
    .activity_subcontainer > p {
        margin-bottom: 0px;
    }

    .activity_subheader_item {
        flex-grow: 1;
        text-align: center;
        color: rgba(255, 255, 255, 0.5);
    }

    .activity_body.activity_body_fixed_height {
        height: calc(100vh - 143px);
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .activity_simple_format > p {
        margin-bottom: 5px;
        font-size: 1rem;
        line-height: 1.5;
    }

    .activity_subheader_item.activity_subheader_item_selected {
        color: white;
        border-bottom: 3px solid white;
    }

    .phone_activity_container.activity_with_planning_menu {
        z-index: 5;
    }


    p.colibri-list-subtitle {
        margin-bottom: 0px;
        margin-left: 15px;
        padding-top: 5px;
        color: #2B384E;
        font-size: 0.9em;
    }

    p.colibri-fc-list-body {
		margin: 0px 0px 4px 15px;
		margin-left: 15px;
		background-color: white;
		opacity: 1;
		padding: 4px 6px 4px 6px;
		border: 0.4px solid #2B384E;
		border-radius: 3px;
    }

    div.fc-list.fc-listDay-view.fc-view {
        border: none !important;
    }

    div.fc-list-day-cushion.fc-cell-shaded {
        background-color: transparent;
        padding: 2px 14px 2px 14px;
    }

    #index-container {
        width: 100% !important;
        margin: 0px !important;
        padding: 0px 3px 60px 3px ;
    }

    #post-lookup-container {
        padding: 5px;
        margin-bottom: 10px;
        border-bottom: 0.3px solid #ccc;
    }

    .colibri-subcontainer-title.subcontainer-no-display {
        display: none;
    }

    #lookup-button-container {
        margin-bottom:10px;
    }

    div.fc-list-empty-wrap2 {
        display: block !important;
    }

    #planning-container {
        overflow-y: scroll;
        overflow-x: hidden;
    }


    .fc-toolbar.fc-header-toolbar {
        width: calc(100% + 6px);
        margin-top: 3px;
        margin-left: -3px;
        padding-left: 3px;
        padding-right: 3px;
        margin-bottom: 3px;
    }

    #posts-index-container {
        margin-bottom: 80px;
    }

    #index-table-container {
        margin-bottom: 80px;
    }

	#account-edit-body {
		padding: 0px;
    }
    
    /* flash layouts */

    .alert {
        padding-left: 10px !important;
        padding-right: 10px !important;
        padding-top: 7px;
        padding-bottom: 8px;
        border-radius:0px !important;
    }

    /* General fullcalendar layouts */

    .fc-event-title.title-responsive {
		font-size: 1.1em;
		color: black;
    }
    
    .fc-event > .fc-event-main {
        overflow: hidden !important;
    }
    
    .fc-event > .fc-event-main > .fc-event-main-frame {
        overflow: hidden !important;
    }

    .fc-event-main-frame > .fc-event-title-container {
        overflow: hidden !important;
    }

    .fc-event {
        overflow: hidden !important;
    }

	.fc-event-time.fc-time-responsive {
		font-size: 0.7em;
		color: black;
		font-weight: normal;
		display: block;
	}

    /* Completion Reports layouts */

    .completion-report-container {
        padding: 5px;
        margin-top:5px;
    }

    .completion-report-message-heading {
        display:flex;
        justify-content:space-between;
        font-size: 0.9em;
    }

    .completion-report-comment {
        border-top: 1px solid #2B384E;
        padding-top: 5px;
    }

    .completion-report-action {
        padding-top:6px;
        margin-top:6px;
        border-top:1px solid #ccc;
    }

    .completion-report-tasks {
        margin-bottom: 8px;
    }

    #desktop-reports-index-header {
        display: none;
    }

    #japanese-query-date {
        font-size:1.3em;
        text-align:center;
        color: #212529;
        line-height: unset;
    }

    #more-planning-menu-items {
        position: fixed;
        background-color: #fdfdfd;
        z-index: 10000;
        border: 0.5px solid #ccc;
		border-radius: 6px;
    }

    li.planning-menu-items {
        background-color: transparent;
    }

    li.planning-menu-items.planning-menu-selected {
		color: #626669;
        background-color: transparent;
    }
    
    li.planning-menu-items:hover, li.planning-menu-items.colibri-clickable-link:hover {
        background-color: transparent !important;
        color: #626669;
    }
    
    li.planning-menu-items:focus {
        background-color: transparent !important;
        color: #626669;
    }

    .planning-menu-items.planning-menu-selected {
        border: none !important;
    }

    .desktop-margin-top {
        margin-top: 0px;
    }

	#planning-header-container.mb-no-box-shadow {
		box-shadow: none;
	}

    #toggle-patients-nurses-wrapper {
        margin-top:15px;
    }

    /* List View */

    .fc-list.fc-view {
        border: none;
    }

    .fc-list-day-text {
        font-weight: bold;
    }

    .fc-list-day-cushion.fc-cell-shaded {
        padding-bottom:2px;
        padding-top: 0px;
        background-color: white;
    }

    .fc-list-empty {
        display: flex;
        flex-direction: column;
        height: 70px;
    }

    .fc-list-day.fc-day, .fc-list-day.fc-day.colibri-listday-no-events {
        width: 100%;
        background-color: white;
        border-bottom: 1px solid rgb(221, 221, 221);
        padding: 2px 14px 2px 14px;
        text-align: left;
        font-weight: bold;
    }

    .colibri-no-events-text {
        width: 100%;
        background-color: white;
        text-align: center;
    }

    .fc-button.fc-button-primary {
		background-color: #FBFDFB;
		border-color: #FBFDFB;
		color: rgb(115, 120, 124);
		border: 0.5px solid rgb(161, 171, 180);
	}

	.fa.fa-edit.coli-bottom-menu-icon {
		margin-left:5px;
	}

	.fc-button-primary:not(:disabled):active, .fc-button-primary:not(:disabled).fc-button-active {
		background-color: rgb(161, 171, 180);
		border-color: rgb(161, 171, 180);
	}

	.fc-button-primary:disabled {
		display: none !important;
    }
    
    .fc-view-harness {
        overflow-x: scroll;
    }

    .colibri-resourceview-title,h2.fc-toolbar-title {
        color: black;
		font-weight: bold;
		font-size: 1.0em !important;
    }

    table.activity-details-table > tbody > tr > td, table.activity-details-table > tbody > tr > td > p {
        font-size: 1em;
        vertical-align: top;
        padding-bottom: 3px;
        margin-bottom: 0;
    }

    li.planning-menu-items  {
        line-height: 18px !important;
    }

    /* css for contact form */

    #call-colibri {
        display: inline;
    }

    /* css for mode of transportation */

    .colibri-radio-item {
        width:100%;
        line-height: 45px;
        margin-bottom: 10px;
        padding: 6px 10px 6px 10px;
        background-color: #eff1f1;
        border: 2px solid #eff1f1;
        border-radius: 4px;
    }

    .colibri-radio-item-content {
        display: flex;
    }

    .colibri-radio-item.radio-selected {
        background-color: #E0FDEC;
        border: 2px solid #6BCD93;
    }

    .colibri-radio-label {
        width: calc(100% - 91px);
        color: #4f5b66;
        padding-left: 15px;
        font-size: 1.3em;
    }

    #transportation-form-container {
        -webkit-flex-grow: 1;
        flex-grow:1;
    }
    
    .colibri-radio-checkmark {
        width: 45px;
    }

    .colibri-radio.glyphicon {
        font-size: 16px;
    }

    #attendance-menu-icon {
        padding-bottom: 0px !important;
    }

	/* Users settings layout */


    #colibri-presentation-text {
        text-align:center;
    }

    #phone-action-container {
        position:fixed;
        right:15px;
        bottom:145px;
        z-index:15;
        background-color:#fafafa;
        border-radius:4px;
        padding:8px 12px 8px 12px;
        box-shadow:0px 1px 2px #ccc
    }
    
    #phone-reflect-button-wrapper {
        display: block !important;
        position:fixed;
        right:15px;
        z-index:15
    }
    
    #phone-reflect-button {
        width:60px;
        height:60px;
        text-align:center;
        border-radius:50%;
        color:#748493;
        box-shadow:0px 1px 2px #ccc;
    }
    
    .phone-action-icon {
        line-height:60px;
        font-size:1.5em
    }

}