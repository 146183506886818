.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
}

#planning-view-container {
  display: flex;
}

#planning-menu-container {
  height: 100%;
  width: 58px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #415465;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: scroll;
}

.glyphicon-selected {
  color: white;
}

.glyphicon-default {
  color: #97a0a8;
}

#planning-show-main {
  margin-left: 55px;
  margin-right: 5px;
}

#planning-menu-title {
  font-size: 1.8em;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  color: #4f4f4f;
}

#planning-menu-list {
  font-size: 2em;
  display: block;
}

#address-phone-container {
  color: #4f4f4f;
  margin-top: 3px;
  font-size: 0.9em;
}

#resource-search-icon {
  display: none;
}

#close-resource-list {
  display: none;
}

li.planning-menu-items {
  font-size: 2em;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  height: 48px;
  width: 48px;
  margin: 2px 5px;
  line-height: 54px;
  color: rgba(255, 255, 255, 0.6);
  border-radius: 6px;
}

li.planning-menu-items:hover {
  cursor: pointer;
  color: white;
  background-color: rgba(0, 0, 0, 0.25);
}

li.planning-menu-selected {
  color: white;
  padding-left: -2px;
  background-color: rgba(0, 0, 0, 0.25);
}

.left-menu-nav-item {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  height: 48px;
  width: 48px;
  margin: 3px 5px;
  border-radius: 6px;
  color: rgba(255, 255, 255, 0.6);
}

.left-menu-nav-item:hover {
  cursor: pointer;
  color: white;
  background-color: rgba(0, 0, 0, 0.25);
}

#link-to-general-settings.planning-menu-selected {
  color: white;
  background-color: rgba(0, 0, 0, 0.25);
}

#left-menu-nav {
  margin-bottom: 15px;
}

span.left-menu-icon {
  line-height: 48px;
}

.desktop-margin-top {
  margin-top: 20px;
}

#colibri-data-top-menu {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px 5px 5px 12px;
}

#planning-container {
  display: flex;
  padding-left: 8px;
  padding-right: 8px;
}

.calendar {
  max-width: 100%;
}

#calendar {
  min-width: 100%;
}

.nurse_calendar {
  max-width: 100%;
}

#main-planning-header {
  margin-top: 7px;
  display: flex;
  flex-direction: row-reverse;
}

#planning-activity-module {
  padding-top: 10px;
  padding-left: 10px;
}

.planning-activity-module-title {
  font-weight: bold;
  font-size: 1.5em;
  text-align: center;
  display: block;
}

.planning-nurse-module-title {
  font-weight: bold;
  font-size: 1.5em;
}

.master-title {
  font-weight: bold;
  font-size: 1.5em;
  text-align: center;
  display: block;
}

#index-container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

#index-table-container {
  margin-top: 12px;
}

#planning-top-right-action-container {
  text-align: right;
  display: flex;
}

.coli-horizontal-menu {
  padding-left: 15px;
  padding-right: 25px;
  padding-bottom: 5px;

  color: #97a0a8;
  font-size: 1.2em;
}

.coli-horizontal-menu:hover {
  cursor: pointer;
}

.coli-horizontal-menu.horizontal-menu-selected {
  color: #4f5b66;
  border-bottom: 3px solid #6d7882;
}

#see-more-service-category-data:hover {
  cursor: pointer;
}

#planning-page-wrapper {
  position: absolute;
  left: 223px;
  top: 0px;
  width: calc(100vw - 223px);
}

#planning-page-wrapper.no-resources-width {
  width: calc(100vw - 58px);
  left: 58px;
}

#detail-container {
  display: flex;
  width: 100%;
}

#detail-planning-title {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin-bottom: 15px;
}

#payable-title-wrapper {
  display: flex;
  padding-left: 12px;
}

#color-select-selectized {
  position: absolute;
  left: -100000px;
}

#planning-header-container {
  margin-bottom: 15px;
  box-shadow: 0px 1px 2px #ccc;
  padding-top: 16px;
  padding-right: 10px;
}

#detail-planning-container {
  width: 100%;
  height: 100%;
}

#smartphone-planning-wrapper {
  height: 100%;
}

#resource-list-container {
  background-color: #415465;
  border-left: 0.5px solid rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 165px;
  position: fixed;
  z-index: 0;
  top: 0;
  left: 58px;
  overflow-x: hidden;
  padding-top: 20px;
  padding-left: 2px;
}

#settings-menu-container {
  height: 100%;
  width: 200px;
  position: fixed;
  z-index: 0;
  top: 0;
  left: 58px;
  padding-top: 20px;
  background-color: #415465;
  border-left: 0.5px solid rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
}

#resource-container {
  overflow-y: auto;
}

#nurses-resource,
#patients-resource {
  padding-bottom: 30px;
}

#new-assign-mitei-nurses:hover {
  cursor: pointer;
}

.resource-subsection-title {
  margin-top: 8px;
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: 2px;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.6);
  color: white;
  font-size: 0.95em;
}
/* -------- */

.colibri-resource-title {
  margin-top: 8px;
  margin-left: 3px;
  margin-right: 3px;
  color: white;
  font-size: 1em;
}

.colibri-selectable-title {
  padding-left: 5px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  margin-right: 0px;
}

.colibri-selectable-title:hover {
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.colibri-resource-subtitle {
  padding-left: 4px;
  margin-top: 3px;
  margin-left: 3px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1em;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.colibri-resource-subtitle:hover {
  color: white !important;
  background-color: rgb(0, 0, 0, 0.25);
}

.colibri-bottom-border {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.6);
}

.colibri-resource-menu-subcontainer {
  margin: 10px 0px 10px 6px;
}

/* will be deprecated from this line below */
p.resource-list-element,
a.planning-list-element.colorize,
.master-list-element {
  font-size: 13px;
  line-height: 18px;
  padding-bottom: 3px;
  padding-top: 3px;
  margin-bottom: 0;
  margin-left: 8px;
  padding-left: 8px;
  font-size: 0.9em;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: rgba(255, 255, 255, 0.8);
  overflow-x: hidden;
}

.resource-list-element:hover,
a.planning-list-element.colorize:hover {
  color: white;
  cursor: pointer;
  background-color: rgb(0, 0, 0, 0.25);
}

.resource-list-element.resource-selected {
  background-color: rgba(0, 0, 0, 0.25);
  color: white;
}

/* will be deprecated from this line above */

.colibri-resource {
  line-height: 18px;
  padding: 3px 0px 3px 6px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-bottom: 0;
  font-size: 0.9em;
  color: rgba(255, 255, 255, 0.8);
}

.colibri-resource:hover {
  color: white;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.25);
}

.colibri-resource.resource-selected,
.colibri-resource-title.resource-selected {
  background-color: rgba(0, 0, 0, 0.25);
  color: white;
}

#master-mode {
  width: calc(100% + 30px);
  margin-left: -20px;
  margin-right: -10px;
  margin-top: -10px;
  z-index: 1;
  color: #4f4f4f;
  background-color: #beffb7;
  text-align: center;
  margin-bottom: 10px;
  min-height: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

#duplicate-button-wrapper {
  margin-top: 10px;
  margin-bottom: 5px;
}

#no-duplicate-button-wrapper {
  text-align: center;
}

#toggle-patients-nurses-wrapper {
  margin-bottom: 7px;
  margin-left: auto;
  margin-right: auto;
}

.appointment-comment {
  margin-bottom: 4px;
  font-size: 1em;
}

.chosen-container {
  font-size: 1em !important;
}

ul.chosen-results {
  font-size: 1em !important;
}

#delete-planning-action {
  margin-bottom: 30px;
  text-align: center;
}

.settings-list-element {
  font-size: 1.1em;
  color: rgba(255, 255, 255, 0.8) !important;
  margin-bottom: 2px;
  padding-top: 1px;
  padding-bottom: 2px;
  padding-left: 8px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.settings-list-element:hover {
  color: white;
  cursor: pointer;
  background-color: rgb(0, 0, 0, 0.25);
}

.settings-list-element:hover > a {
  color: white !important;
}

.settings-list-element.settings-list-element-selected {
  color: white;
  background-color: rgb(0, 0, 0, 0.25);
}

a.settings-list-url {
  display: block;
}

.settings-list-element.settings-list-element-selected > .settings-list-url {
  color: white !important;
}

ul.settings-list {
  font-size: 0.85em;
  padding-left: 8px;
  list-style: none;
}

#close-settings {
  display: none;
}

.settings-list-element {
  width: 100%;
}

#planning-header-submenu {
  display: none;
}

#nurse_filter_wrapper {
  min-width: 400px;
}

#nurse_filter_wrapper > .selectize-control {
  width: 400px;
}

.forty-percent-width {
  width: 40%;
}

.fifty-percent-width {
  width: 50%;
}

.sixty-percent-width {
  width: 60%;
}

#settings-menu-list {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 30px;
}

#settings-container {
  position: absolute;
  left: 258px;
  top: 0px;
  width: calc(100vw - 258px);
  height: 100vh;
  overflow-y: scroll;
}

.settings-body {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 30px;
  height: auto;
  overflow-y: scroll;
}

.settings-list-header {
  font-size: 1.2em;
  color: rgba(255, 255, 255, 0.6);
  border-bottom: solid rgba(255, 255, 255, 0.6) 0.5px;
  margin-bottom: 7px;
  margin-left: 8px;
  margin-right: 8px;
  padding-bottom: 7px;
}

#planning-options {
  width: 90%;
  max-width: 550px;
  margin-bottom: 30px;
}

#printing-options {
  max-width: 300px;
}

.colibri-xs-title {
  color: #4f4f4f;
  font-size: 1.15em;
  font-weight: bold;
  padding-bottom: 5px;
}

ul#left-menu-ul {
  border-bottom: 0px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0 !important;
  overflow: hidden;
  font-size: 13px;
}

#resource-detail-header {
  display: flex;
  justify-content: space-between;
}

#resource-name-wrapper,
.resource-title-after {
  font-weight: bold;
  font-size: 1.5em;
  color: black;
  padding-right: 10px;
  padding-top: 10px;
}

.resource-title-selectable:hover {
  cursor: pointer;
  color: white;
}

#nurse-conflict-confirm {
  display: none;
}

#appointment-comments-container {
  width: 100%;
  margin-left: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.1em;
}

#appointment-comments {
  margin-left: 0px;
}

.appointment-comments-title {
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 0px;
}

#description-container {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.colibri-form-subsection {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 5px;
}

.colibri-form-subsection-title {
  margin-top: 15px;
  padding-bottom: 5px;
  border-bottom: 0.5px solid #ddd;
  margin-bottom: 4px;
  font-size: 1.15em;
  color: black;
}

.report-download-wrapper {
  margin-top: 5px;
}

#colibri-master-action-button:hover {
  cursor: pointer;
}

.batch-action-button {
  position: fixed;
  text-align: center;
  vertical-align: middle;
  z-index: 6;
  width: 56px;
  height: 56px;
  border-radius: 50px;
  background: #fc7181;
  bottom: 80px;
  right: 60px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
}

.batch-action-button:hover {
  background: #f15365;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25), 0 6px 6px rgba(0, 0, 0, 0.22);
}

span.glyphicon.glyphicon-pencil.batch-action-pencil {
  color: white;
  line-height: 40px;
}

.master-reflect-option:hover {
  cursor: pointer;
  color: black !important;
}

p.master-reflect-option {
  margin-bottom: 5px;
}

.glyphicon-action-button {
  color: #85929e;
}

.colibri-action {
  height: 28px;
  line-height: 28px;
}

.colibri-action:hover {
  cursor: pointer;
}

.colibri-toggle-switch {
  display: flex;
  padding: 0px !important;
  margin: 0px !important;
  height: 40px;
}

.colibri-toggle-handle {
  padding: 0px !important;
  height: 34px;
  width: 6px;
  margin: 0px 2px 2px 2px;
}

.colibri-handle-left {
  border-radius: 4px 3px 3px 4px !important;
}

.colibri-handle-right {
  border-radius: 3px 4px 4px 3px !important;
}

.colibri-toggle-text-left {
  margin: 6px 12px 6px 18px;
}

.colibri-toggle-text-right {
  margin: 6px 18px 6px 12px;
}

#planning-horizontal-menu-container {
  display: flex;
  justify-content: space-between;
}

#colibri-horizontal-menu {
  display: flex;
}

#planning-header {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
}

#new-reminder-email:hover {
  cursor: pointer;
}

#print-button:hover {
  cursor: pointer;
}

/* styles for completion reports index */

.resource-details-subsection-title {
  margin-left: 5px;
  font-size: 1.2em;
  font-weight: bold;
}

.resource-details-subsection-body {
  color: #4f4f4f;
}

.write-completion-report {
  color: #4f4f4f;
}

.hamburger_menu {
  display: none;
}

td.report-caution {
  background-color: #ffff9b;
}

td.report-items {
  padding: 0px !important;
}

/* styles for left menu backdrop */

#menu-backdrop {
  display: none;
}

#settings-menu {
  display: flex;
}

.settings-list-icon {
  display: inline-block;
  font-size: 14px;
  width: 17px;
}

.coli-horizontal-menu-container {
  display: flex;
  justify-content: space-between;
}

p.setting-title {
  margin-bottom: 0;
  line-height: 1.5em;
}

#batch-action-menu {
  width: 140px;
  margin-left: -70px;
  margin-bottom: 15px;
  background-color: white;
  font-size: 0.85em;
  text-align: left;
  padding: 6px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

#batch-action-menu.batch-master-button {
  width: 90px;
  margin-left: -40px;
}

.fc-icon.glyphicon.glyphicon-calendar {
  font-size: 1.25em !important;
  margin-top: -3px;
}

.fc-icon.glyphicon.glyphicon-filter {
  font-size: 1.15em !important;
}

.fc-icon.glyphicon.glyphicon-calendar:hover,
.fc-icon.glyphicon.glyphicon-filter:hover {
  color: #4f5b66 !important;
}

#phone-action-container {
  display: none;
}

#phone-reflect-button-wrapper {
  display: none;
}

#planning-menu-wrapper {
  height: 100vh;
  overflow: hidden;
}

@media print {
  #index-container {
    width: 95%;
  }

  #index-table-container {
    margin: 0px;
  }

  #planning-page-wrapper {
    position: static;
    margin: 0px;
  }

  #detail-container {
    max-width: none;
    width: 100% !important;
  }

  #resource-print-info {
    max-height: 40px;
  }

  #planning-header-container {
    box-shadow: none;
  }

  .payable-body {
    width: 100% !important;
  }

  #planning-menu-wrapper {
    height: auto !important;
    overflow: visible !important;
    width: 1px !important;
  }

  #settings-container {
    position: static;
    margin-left: 0px;
    width: 100vh;
    height: auto;
  }
}

@media screen and (orientation: portrait) {
  #detail-container {
    height: 100vh;
    overflow: visible;
  }

  #detail-planning-container {
    height: auto;
  }

  #colibri-batch-action-button {
    height: 90px;
    width: 90px;
    border-radius: 90px;
  }

  span.glyphicon.glyphicon-pencil.batch-action-pencil {
    line-height: 74px;
    font-size: 1.7em;
  }
}
