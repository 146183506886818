.activity-wrapper {
	border-bottom: 0.5px solid #aaa;
	font-size: 0.85em;
	color: #4f5b66;
	display: flex;
}

.activity-icon-container {
	padding: 5px;
	padding-top: 13px;
}

.activity-datetime {
	color: #bbb;
}

.activity-important-fields {
	font-weight: bold;
}

.activity-before-update-state {
	color: #aaa;
}

#activity-filter {
	padding-left:12px;
	padding-right: 12px;
	padding-bottom:8px
}