.toggleable-subsection-title:hover {
  cursor: pointer;
}

.glyphicon.completion-report-glyphicon {
  font-size: 0.7em;
  cursor: pointer;
  margin-left: 4px;
}

.report-checkmark {
  margin-right: 3px;
  padding-bottom: 2px;
  font-size: 8px;
  color: #42b97c;
}

#completion-reports-container {
  width: 100%;
  margin-left: 15px;
}

.before-appointment-label {
  width: 54px;
}

.report-prefilled-item {
  color: black;
}

.colibri-checkbox {
  width: 15px;
  height: 15px;
  border-radius: 2px;
}

.colibri-horizontal-menu-container {
  display: flex;
  border-bottom: 3px solid #dee2e6;
  justify-content: space-between;
}

.colibri-horizontal-menu-item {
  font-size: 1.3em;
  text-align: center;
  color: #97a0a8;
  line-height: 44px;
  padding-left: 12px;
  padding-right: 12px;
}

.colibri-horizontal-menu-item.attendance-menu {
  min-width: 75px;
}

.colibri-horizontal-menu-item.item-selected {
  border-bottom: 2px solid #606e7c;
  color: #606e7c;

  /* make the bottom border the full width of the parent container if only one option can be selected */
  &:only-child {
    width: 100%;
  }
}

.colibri-horizontal-menu-item:hover {
  cursor: pointer;
  color: #606e7c;
}

#japanese-query-date {
  font-size: 1.3em;
  text-align: center;
  color: #212529;
}

.completion-report-message-body {
  background-color: #defcfc;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 3px;
}

.completion-report-message-body > p {
  margin-bottom: 3px;
}

.completion-report-notification {
  margin-top: 6px;
  padding-top: 6px;
  border-top: 0.5px solid #ccc;
}

.report-comparison-attribute {
  color: black;
}

.report-comparison-details {
  color: #5f6368;
}

.report-comparison-item-separator {
  width: 50px;
  height: 8px;
  border-bottom: 1px solid #85929e;
  margin-bottom: 8px;
}

.care-instructions {
  color: #606e7c !important;
}

table.reportable-details-table > tbody > tr > td {
  padding: 0px 15px 0px 0px;
  font-size: 1em;
}

.toggle-down {
  float: left;
  margin-right: 4px;
}

.report-flag {
  width: 11px;
  height: 14px;
  display: inline-block;
}

.report-flag-text {
  margin-bottom: 2px;
  font-weight: bold;
}

.nurse-ping-flag {
  background-color: #ff9090;
}

.changed-service-type-flag {
  background-color: #adf7b6;
}

.changed-from-forecast-flag {
  background-color: #fbc32d;
}

.spot-appointment-flag {
  background-color: #7ad5de;
}

.edit-requested-appointment-flag {
  background-image: repeating-linear-gradient(
    45deg,
    #c8f6df,
    #c8f6df 5px,
    #99e6bf 5px,
    #99e6bf 10px
  ) !important;
}

.changed-datetime-flag {
  background-color: #a9abff;
}

input.colibri-input[readonly="readonly"] {
  background-color: #f0f0f0 !important;
}

#desktop-reports-index-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #ddd;
  margin-bottom: 5px;
}

#reports-header {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px 5px 5px 0px;
  display: flex;
  justify-content: space-between;
}

#reports-filters {
  display: flex;
  gap: 5px;
  vertical-align: bottom;
}

#appointments-and-instructions {
  width: 65%;
  padding: 6px;
}

#recent-information-container {
  width: 35%;
  padding-top: 6px;
}

.report-warnings {
  width: 45%;
}

.report-detailed-items {
  width: 55%;
}

.vital-header {
  width: 55px;
}

.medical-report-item {
  margin-bottom: 7px;
  padding-bottom: 5px;
  border-bottom: 1px dotted #ccc;
}

.item-header {
  margin-bottom: 3px;
}

.thread-item-images-container {
  display: flex;
}

.thread-report-icon-badge {
  margin-top: 10px;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: #eff1f1;
  text-align: center;
  vertical-align: middle;
  padding-top: 4px;
}

.entry-button {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-bottom: 60px;
}

.start-form-info {
  padding-bottom: 5px;
  border-bottom: 1px dotted #ccc;
}

.form-info-title {
  color: #828e99;
  margin-bottom: 4px;
}

.form-info-body {
  font-size: 0.9em;
}

.reportable-description {
  padding-bottom: 4px;
  border-bottom: 1px dotted #ccc;
  font-size: 0.9em;
}

.horizontal-scrollable-menu {
  display: flex;
  gap: 5px;
  white-space: nowrap;
  overflow-x: scroll;
  padding-bottom: 7px;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.horizontal-scrollable-menu ::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
}

@media screen and (orientation: portrait) {
  .colibri-horizontal-menu-container {
    max-width: 100%;
  }

  .colibri-horizontal-menu-item {
    font-size: 1.2em;
    text-align: center;
    color: #97a0a8;
    line-height: 44px;
    padding-left: 0;
    padding-right: 0;
  }

  .colibri-horizontal-menu-item.sp-attendance-menu {
    min-width: 65px;
  }
}
