.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  line-height: 17px;
}

.switch.switch-lg {
  width: 38px;
  height: 21px;
  line-height: 21px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 3px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 3px;
}

.slider.slider-lg:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 3px;
}

.information-item {
  padding: 8px 5px 8px 5px;
}

.information-item.bottom-border {
  border-bottom: 1px solid #ddd;
}

.my-profile-group {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #fdfdfd;
  border-radius: 6px;
  padding: 12px 5px 12px 5px;
  border: 1px solid #ddd;
}

input:checked + .slider-blue {
  background-color: #2196f3;
}

input:focus + .slider-blue {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider-pink {
  background-color: #fce7ea;
}

input:focus + .slider-pink {
  box-shadow: 0 0 1px #fce7ea;
}

input:checked + .slider-cyan {
  background-color: #17a2b8;
}

input:focus + .slider-cyan {
  box-shadow: 0 0 1px #17a2b8;
}

input:checked + .slider-success {
  background-color: #30af4d;
}

input:focus + .slider-success {
  box-shadow: 0 0 1px #30af4d;
}

input:checked + .slider-cancelled {
  background-image: repeating-linear-gradient(
    45deg,
    #ffbfbf,
    #ffbfbf 5px,
    #ff8484 5px,
    #ff8484 10px
  );
}

input:focus + .slider-cancelled {
  box-shadow: 0 0 1px #ffbfbf;
}

input:checked + .slider-edit-requested {
  background-image: repeating-linear-gradient(
    45deg,
    #c8f6df,
    #c8f6df 5px,
    #99e6bf 5px,
    #99e6bf 10px
  );
}

input:focus + .slider-edit-requested {
  box-shadow: 0 0 1px #c8f6df;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

input:checked + .slider.slider-lg:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#users-index-container {
  margin-left: 10px;
  margin-right: 10px;
}

.account-container-sm {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 100%;
}

.account-container {
  width: 45px;
  height: 45px;
  text-align: center;
  border-radius: 100%;
}

.account-name-sm {
  line-height: 40px;
  font-weight: bold;
  color: white;
}

.account-name {
  line-height: 45px;
  font-weight: bold;
  color: white;
}

.coli-profile-icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.coli-xs-profile-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

@media print {
  .account-container {
    border: 0.5px solid #ccc;
  }
  .coli-profile-icon {
    border: 0.5px solid #ccc;
  }
}