.colibri-devise-form {
	width : 470px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 60px;
	margin-bottom: 40px;
	background-color: #fff;
	padding-left: 35px;
	padding-right: 35px;
	padding-top: 15px;
	padding-bottom: 35px;
	border-radius: 3px;
	border: 1px solid #ddd;
}

.remember-me {
	color: #aaa;
	font-size: 1em;
}

#colibri-devise-links {
	margin-top: 15px;
}

.colibri-devise-title {
	font-size: 2.2em;
	line-height: 1em;
	color: #4f5b66;
	text-align: center;
	padding: 20px;
}

.colibri-form-label {
	color: #85919C;
}

.colibri-sm-label {
	font-size: 0.9em;
}

.colibri-form-label-dark {
	color: #4f5b66;
	font-size: 1em;	
}

.colibri-form-label-dark-md {
	color: #586571;
	font-size: 1em;	
}

.colibri-form-label-xtra-dark {
	color: #49545e;
	font-size: 1em;	
}

.colibri-form-options-label {
	color: #4f5b66;
}

#account-update-container {
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	background-color: #fff;
	border: 0.5px solid #aaa;
	min-height: 700px;
	margin-top: 20px;
	margin-bottom: 50px;
}

#account-update-menu {
	width: 200px;
	border-right: 0.5px solid #aaa;
}

.account-menu-selected {
	color : black;
}

.account-menu-item:hover {
	cursor: pointer;
	color: black;
}

.account-menu-item {
	border-bottom: 0.5px solid #aaa;
	color :#4f5b66;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 8px;
	padding-right: 8px;
}

#account-update-body {
	width: 100%;
}

.user-clickable-row:hover {
	cursor: pointer;
}
