@media screen and (orientation: portrait) and (min-width: 761px) and (max-width: 1030px) {
  #colibri-nav-container {
    margin-left: 15px !important;
  }

  #top-subsection {
    margin-bottom: -60px !important;
  }

  .ipad-no-display {
    display: none !important;
  }
  .ipad-no-display {
    display: none !important;
  }

  .ipad-only-display {
    display: block !important;
  }
  .ipad-only-display {
    display: block !important;
  }

  #payable-container.no-resources-width {
    width: calc(100vw - 58px) !important;
    left: 58px;
  }
  #payable-container.no-resources-width {
    width: calc(100vw - 58px) !important;
    left: 58px;
  }

  #detail-container.no-resources-width {
    max-width: calc(100vw - 55px) !important;
    width: calc(100vw - 55px) !important;
  }
  #detail-container.no-resources-width {
    max-width: calc(100vw - 55px) !important;
    width: calc(100vw - 55px) !important;
  }

  #planning-header {
    display: block !important;
  }
  #planning-header {
    display: block !important;
  }

  #nurse_filter_wrapper {
    margin-left: 0px !important;
    margin-top: -13px;
    margin-bottom: 7px;
  }
  #nurse_filter_wrapper {
    margin-left: 0px !important;
    margin-top: -13px;
    margin-bottom: 7px;
  }

  #reports-header {
    display: flex;
    flex-direction: column-reverse;
  }
  #reports-header {
    display: flex;
    flex-direction: column-reverse;
  }

  #completion-reports-query-date-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  #completion-reports-query-date-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  #reports-filters {
    margin-top: 10px;
  }
  #reports-filters {
    margin-top: 10px;
  }

  #appointments-and-instructions {
    width: 100%;
  }
  #appointments-and-instructions {
    width: 100%;
  }

  #recent-information-container {
    display: none !important;
  }
  #recent-information-container {
    display: none !important;
  }

  .thread-item-body {
    display: block !important;
  }
  .thread-item-body {
    display: block !important;
  }

  .reportable-information,
  .post-information {
    width: 100% !important;
  }

  .report-items,
  .post-readmarks {
    width: 100% !important;
  }

  .report-body,
  .post-content {
    width: 100% !important;
  }

  .report-action,
  .post-action {
    width: 100% !important;
  }
  .reportable-information,
  .post-information {
    width: 100% !important;
  }

  .report-items,
  .post-readmarks {
    width: 100% !important;
  }

  .report-body,
  .post-content {
    width: 100% !important;
  }

  .report-action,
  .post-action {
    width: 100% !important;
  }

  .reportable-info-block,
  .post-info-block {
    display: flex;
  }
  .reportable-info-block,
  .post-info-block {
    display: flex;
  }

  .post-information,
  .reportable-info-container {
    display: flex;
    border-bottom: 1px solid #eff1f1;
  }
  .post-information,
  .reportable-info-container {
    display: flex;
    border-bottom: 1px solid #eff1f1;
  }

  .post-content,
  .report-body {
    margin-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eff1f1;
    margin-bottom: 3px;
  }
  .post-content,
  .report-body {
    margin-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eff1f1;
    margin-bottom: 3px;
  }

  .post-action,
  .report-action {
    text-align: right;
  }
  .post-action,
  .report-action {
    text-align: right;
  }

  .reportable-second-nurse-information {
    display: none !important;
  }
  .reportable-second-nurse-information {
    display: none !important;
  }

  .ipad-no-flex {
    display: block !important;
  }
  .ipad-no-flex {
    display: block !important;
  }

  .report-warnings {
    width: 100%;
  }
  .report-warnings {
    width: 100%;
  }

  .report-detailed-items {
    width: 100%;
  }
  .report-detailed-items {
    width: 100%;
  }

  /* fullCalendar layouts */
  /* fullCalendar layouts */

  .fc-list.fc-view.fc-listDay-view {
    border-color: transparent !important;
  }
  .fc-list.fc-view.fc-listDay-view {
    border-color: transparent !important;
  }

  .fc-list-day.fc-day {
    display: none !important;
  }
  .fc-list-day.fc-day {
    display: none !important;
  }

  .colibri-fc-list {
    border-radius: 6px;
    background-color: #eff2f5;
    padding: 8px;
    margin-top: 6px;
  }
  .colibri-fc-list {
    border-radius: 6px;
    background-color: #eff2f5;
    padding: 8px;
    margin-top: 6px;
  }

  .colibri-fc-list-time {
    font-size: 0.8em;
  }
  .colibri-fc-list-time {
    font-size: 0.8em;
  }

  .colibri-fc-list-body {
    margin-left: 15px;
    margin-bottom: 0px;
    padding: 4px 6px;
    background-color: white;
    border: 0.5px solid #2b384e;
    border-radius: 4px;
  }
  .colibri-fc-list-body {
    margin-left: 15px;
    margin-bottom: 0px;
    padding: 4px 6px;
    background-color: white;
    border: 0.5px solid #2b384e;
    border-radius: 4px;
  }

  .colibri-fc-list-title {
    color: black;
  }
  .colibri-fc-list-title {
    color: black;
  }

  .colibri-list-subtitle {
    margin-bottom: 0px;
    margin-left: 15px;
    font-size: 0.8em;
  }
  .colibri-list-subtitle {
    margin-bottom: 0px;
    margin-left: 15px;
    font-size: 0.8em;
  }

  .fc-list-empty {
    height: auto !important;
    background-color: white !important;
    padding-top: 6px;
    border-top: 1px solid #eff1f1;
  }
  .fc-list-empty {
    height: auto !important;
    background-color: white !important;
    padding-top: 6px;
    border-top: 1px solid #eff1f1;
  }

  .activity_subtitle {
    font-size: 1.3em;
    color: #4f5d66;
    border-bottom: 0.5px dotted #ddd;
    display: flex;
    justify-content: space-between;
  }
  .activity_subtitle {
    font-size: 1.3em;
    color: #4f5d66;
    border-bottom: 0.5px dotted #ddd;
    display: flex;
    justify-content: space-between;
  }

  .toggleable_activity {
    display: none;
  }
  .toggleable_activity {
    display: none;
  }

  .activity_subcontainer {
    padding: 4px 0px;
    margin-top: 5px;
  }

  .activity_subcontainer > p {
    margin-bottom: 0px;
  }

  .coli-report-container {
    margin-bottom: 6px;
  }

  .coli-report-header {
    display: flex;
    justify-content: space-between;
  }

  .coli-report-publisher {
    font-size: 0.85em;
  }

  .coli-report-published-at {
    font-size: 0.85em;
  }

  .coli-report-body {
    background-color: #defcfc;
    padding: 3px 8px 3px 8px;
    border-radius: 3px;
  }

  .details-table-cell > p {
    margin-bottom: 0px;
  }
  .details-table-cell > p {
    margin-bottom: 0px;
  }
}
