#chosen-select-all-container {
    margin-left: 150px;
}

#chosen_target_services_chosen {
    width: 250px !important;
}

.verified-at-and-by {
    margin-left: 4px;
    color: #4f5b66;
    font-size: 0.80em;
}

.verified-at-and-by:hover {
    color: black;
    cursor: pointer;
    font-size:0.80em;
}