.align-right {
  float: right;
}

#payable-container {
  position: fixed;
  margin-left: 223px;
  height: 100vh;
  overflow-y: scroll;
  width: calc(100vw - 213px);
  overflow-x: hidden;
  margin-bottom: 50px;
}

#payable-dashboard {
  min-width: 150px;
}

#chart-and-details-container {
  height: 125px;
  border-left: 0.5px #ccc solid;
  padding-left: 10px;
}

.payable-body {
  padding-bottom: 40px;
  padding-left: 7px;
  padding-right: 7px;
}

p.payable-dashboard-line {
  margin-bottom: 5px;
}

.payable-dashboard-important {
  font-weight: bold;
  font-size: 1.3em;
}

.payable-dashboard-text-container {
  margin-left: 10px;
}

#payable-charts {
  display: flex;
  margin-bottom: 20px;
}

#toggle-patients-nurses {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 7px;
}

#payable-download-button {
  margin-left: auto;
  margin-right: 0;
}

#payable-table-commands {
  display: flex;
  justify-content: space-between;
  padding-bottom: 4px;
  border-bottom: 1px solid #eaecec;
  margin-bottom: 7px;
}

.sunday-holiday-provided-service {
  color: #ff304f !important;
}

.saturday-provided-service {
  color: #118df0 !important;
}

.cancelled-provided-service {
  text-align: center;
  font-size: 1.15em;
  padding-bottom: 2px;
  padding-top: 2px;
  background-image: repeating-linear-gradient(
    45deg,
    #ffbfbf,
    #ffbfbf 5px,
    #ff8484 5px,
    #ff8484 10px
  ) !important;
}

.edit-requested-provided-service {
  text-align: center;
  font-size: 1.15em;
  padding-bottom: 2px;
  padding-top: 2px;
  background-image: repeating-linear-gradient(
    45deg,
    #c8f6df,
    #c8f6df 5px,
    #99e6bf 5px,
    #99e6bf 10px
  );
}

#chosen_custom_email_days_chosen {
  width: 120px !important;
}

#nurse-details-button {
  margin-left: 10px;
}

.planning-nurse-module-title {
  min-width: 100px;
}

.planning-nurse-module-title:hover {
  cursor: pointer;
}

#resource-details-panel {
  background-color: white;
  position: fixed;
  right: 0px;
  top: 0px;
  width: 400px;
  box-shadow: 0px 5px 5px 0px #dde4ec;
  z-index: 15;
  padding: 15px 10px 15px 10px;
  border: 0.5px solid #ddd;
  height: 100vh;
  overflow-y: auto;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

#resource-details-panel::-webkit-scrollbar {
  display: none;
}

#resource-detail-body {
  height: calc(100% - 32px);
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
}

.resource-details-block-title {
  margin-top: 15px;
  padding-bottom: 5px;
  border-bottom: 0.5px solid #ddd;
  margin-bottom: 4px;
  color: #4f4f4f;
}

td.details-table-body {
  text-wrap: wrap;
  padding-right: 7px;
}

.details-table-head-lg {
  width: 215px;
}

.resource-detail-table {
  width: 100%;
}

.resource-detail-table > tr {
  width: 100%;
}

#resource-action-block {
  margin-top: 10px;
  margin-bottom: 20px;
}

.btn-light-custom {
  border: 0.5px solid #eee;
}

#resource-detail-header {
  border-bottom: 0.5px solid #ddd;
  margin-bottom: 5px;
}

div.resource-details-table {
  font-size: 0.85em;
  padding-bottom: 3px;
  padding-left: 0px;
  vertical-align: top;
  width: 100%;
}

.resource-history {
  font-size: 0.85em;
  margin-bottom: 1px;
}

.close-button-span:hover {
  cursor: pointer;
}

#payable-query {
  font-size: 0.7em;
}

.refresh-button {
  margin-bottom: 3px;
  background-color: #bbb;
}

.refresh-button:hover {
  cursor: pointer;
}

.colibri-payable-subtitle {
  margin-top: 20px;
  margin-bottom: 8px;
  font-size: 1.2em;
  color: black;
  font-weight: bold;
}

.nurse-wage-element {
  display: flex;
}

.wage-element-title {
  width: 110px;
  text-align: right;
  font-size: 1em;
}

.attendance-el-title {
  width: 80px;
  text-align: right;
  font-size: 1em;
}

.wage-element-value {
  width: 150px;
  padding-left: 6px;
}

.attendance-el-value {
  width: 180px;
  padding-left: 6px;
}

.wage-element-title.total-wage {
  border-top: 1px solid #ccc;
}

.attendance-container {
  border: 0.5px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  background-color: #fefffe;
}

.attendance-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 0.5px solid #ccc;
}

.attendance-datetime {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

.attendance-summary-container {
  margin-right: 5px;
  border: 0.5px solid #ccc;
  border-radius: 3px;
  width: 60%;
}

.attendance-action-container {
  display: flex;
  justify-content: space-between;
}

.attendance-date-query-container {
  display: flex;
  padding: 15px 10px 15px 0px;
  max-height: 55px;
  justify-content: right;
}

@media print {
  .completion-report-message-body {
    border: 3px solid #defcfc;
    font-size: 16px;
  }

  .completed-report-row {
    border-bottom: 0.5px solid #dee2e6;
  }

  .completed-report-row > td {
    font-size: 16px;
  }

  #payable-container {
    height: auto !important;
    overflow: visible;
    max-width: none;
    min-width: 100vw !important;
    width: 100vw !important;
    margin-left: 0px;
    position: relative;
  }
}
