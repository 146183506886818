#background-task-body {
  padding-left: 0px;
  font-size: 14px;
}

#background-task {
  position: absolute;
  right: 0;
  bottom: 60px;
  height: 92%;
  max-width: 500px;
  width: 100%;
  overflow: scroll;
  z-index: 9;
}

#reports-modal-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  height: 60px;
  padding-top: 12px;
}

@media (min-width: 576px) and (max-width: 768px) {
  #background-task {
    width: 60%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  #background-task {
    margin: 8px;
    width: 50%;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  #background-task {
    margin: 8px;
    height: 60%;
    width: 40%;
    bottom: 0;
  }
}
