.form-group.colibri-datetime-group {
	display: flex;
}

.colibri-datetime-input-container {
	display: flex;
}

.colibri-datetime-separator {
	margin-left: 2px;
	margin-right: 7px;
}

.appointment-occurrence {
	border-bottom: 5px;
	display: flex;
}

.appointment-occurrence-date {
	font-weight: bold;
}

.blue-background-form {
	background-color:  #fafafa;
	margin-left: 1px;
	margin-right: 1px;
	padding: 15px;
}

.recurring-appointment-form-container {
	background-color:#BEFFB7;
	border-bottom-left-radius:5px;
	border-bottom-right-radius:5px;
	margin-left:0.5px;
}

.recurring-appointment-form {
	background-color: white;
	padding: 12px;
	margin: 4px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.form-options-header {
	text-align: center;
	color: #4f5b66;
	padding-bottom: 7px;
}

#form-save {
	display: none;
}

#form-edit-list {
	display: none;
}

#recurring_appointment_nurse_id_chosen, #recurring_appointment_patient_id_chosen {
    width: 130px !important;
}