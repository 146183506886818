.coli-reactions-dropdown {
  border: 1px solid #ccc;
  background-color: #4f5b66 !important;
  border-radius: 3px;
  z-index: 100;
}

ul.coli-reaction-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  justify-content: center;
  vertical-align: middle;
}

li.coli-reaction {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  text-align: center;
  margin-left: 4px;
  margin-right: 4px;
  line-height: 40px;
}

li.coli-reaction:hover {
  background-color: #3b4d61;
  cursor: pointer;
}

.user-reactions-container {
  line-height: 45px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.reactions-index-link:hover {
  border-bottom: 1px dotted #0075c4;
  color: #0075c4 !important;
  cursor: pointer;
}

.reactions-index-link:hover .coli-text-charcoal {
  color: inherit;
}

.reaction-count {
  line-height: 23px;
}
