.teikyohyo-container {
    margin: 5px;
    padding: 5px;
    border: 0.5px solid #ccc;
}

.teikyohyo_title {
    font-weight: bold;
    font-size: 1.3em;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 15px;
}

.care-manager-corporation-header {
    padding: 6px 0px 6px 0px;
	border-bottom: 0.5px solid #dee2e6;
}

.care-manager-corporation-header:hover {
    cursor: pointer;
}

.toggle-arrow-left {
    margin-right: 5px;
}