.ie9 img[src$=".svg"] {
  width: 100%;
}

.colibri-regular-link {
  color: #17a2b8;
  padding-bottom: 2px;
  display: inline-block;
  max-width: 100%;
  overflow-wrap: break-word;
}

.coli-dotted-link {
  border-bottom: 1px dotted #17a2b8;
}

.colibri-regular-link:hover {
  color: #108ea1;
}

.coli-dotted-border {
  border-bottom: 1px dotted;
}

.mb-20px {
  margin-bottom: 20px;
}

.max-h-500px {
  max-height: 500px;
}

#colibri-pages-nav {
  background: white;
  color: black;
  z-index: 30;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 53px;
  display: flex;
  justify-content: space-between;
  -webkit-box-shadow: 0 4px 2px -2px #ccc;
  -moz-box-shadow: 0 4px 2px -2px #ccc;
  box-shadow: 0 4px 2px -2px #ccc;
}

#main-image-responsive {
  display: none;
}

.smartphone-portrait-only {
  display: none;
}

#colibri-clients-responsive {
  display: none !important;
}

a#brand-name {
  color: black;
  font-size: 1.7em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif;
}

span.colibri-brand-inline {
  font-family: Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif;
}

p.company-information {
  margin-bottom: 9px !important;
  color: #d9dce2;
}

#pages-nav-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

#pages-nav-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

#colibri-mobile-nav {
  display: none;
}

.colibri-dropdown {
  position: relative;
  display: inline-block;
}

.colibri-dropdown-content {
  display: none;
  position: absolute;
  top: 40px;
  background-color: white;
  width: 190px;
  border: 1px solid #ccc;
  border-radius: 3px;
  z-index: 1;
  padding: 0.5px;
}

.colibri-dropdown-content a {
  color: #393e46;
  padding: 9px 11px;
  text-decoration: none;
  display: block;
}

.colibri-arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #393e46;
}

.colibri-dropdown-content a:hover {
  background-color: #f8f8f8;
  color: black;
}

.dropdown-show {
  display: block;
}

#colibri-home-pink-pattern {
  width: 100%;
  margin-top: -21.25vw;
}

#colibri-home-pink-pattern-2 {
  width: 100%;
  margin-top: -25vw;
}

#colibri-home-pink-pattern-5 {
  width: 100%;
  margin-top: -50vw;
}

#colibri-home-pink-pattern-smartphone,
#colibri-home-pink-pattern-smartphone-landscape {
  display: none;
}

#service-types-section {
  text-align: center;
  padding-top: 80px;
  margin-top: -40px;
  padding-bottom: 40px;
  margin-bottom: 15px;
}

#top-subsection {
  padding: 80px 50px 0px 70px;
  display: flex;
  height: 600px;
}

#top-subsection.home-top-section {
  background-image: url("../images/colibri_pink_pattern.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
}

#top-subsection.product-top-section {
  background-image: url("../images/colibri_pink_pattern_5.svg");
  background-size: 100%;
  background-repeat: no-repeat;
}

#issues-subsection {
  padding-top: 40px;
  padding-bottom: 0px;
}

#colibri-calendar {
  padding-top: 30px;
}

#colibri-payment {
  padding-top: 0px;
  padding-bottom: 0px;
}

.background-transition {
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  margin-bottom: -2px;
  margin-top: -2px;
  margin-left: -2px;
  margin-right: -2px;
}

#colibri-reporting {
  padding-top: 50px;
  padding-bottom: 30px;
}

.colibri-bottom-action-container {
  max-width: 800px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

.colibri-security-head {
  display: flex;
}

#mobile-only-contact-form-button {
  display: none;
}

#presentation-text {
  width: 55%;
  padding-top: 5px;
}

#colibri-presentation {
  padding: 60px 50px 60px 20px;
  color: #393e46;
  display: flex;
}

#colibri-presentation-text {
  background-color: transparent;
  z-index: 30;
  font-size: 1em;
}

#main-image {
  width: 900px;
}

#colibri-main-image {
  width: 900px;
}

.colibri-home-presentation-title {
  color: #1b263b;
  font-size: 2.7em;
  font-weight: 600;
  line-height: 1.7em;
}

.white-background {
  background-color: white;
}

.light-pink-background {
  background-color: #fce7ea;
}

.light-blue-background-hp {
  background-color: #f3f7fe;
}

.middle-blue-background {
  background-color: #9bbdf9;
}

.section-title-container {
  width: 100%;
  text-align: center;
}

.colibri-section-title {
  font-size: 2.2em;
}

.colibri-home-large-container {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}

.colibri-section-centered-text {
  max-width: 690px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.colibri-home-centered-container {
  max-width: 740px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 120px;
}

#colibri-features-container {
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.colibri-feature-container {
  width: 200px;
  border-radius: 4px;
  margin: 10px;
  border: 1px solid #ccc;
  background-color: white;
  color: #393e46;
}

.colibri-feature-container:hover {
  cursor: pointer;
  color: black;
}

.colibri-feature-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 188px;
  margin: 6px;
}

#calendar-tablet-image {
  display: none;
}

.product-pink-underline {
  height: 6px;
  background-color: transparent;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.colibri-feature-title {
  font-size: 1.25em;
  text-align: center;
  margin-bottom: 4px;
}

footer {
  background-color: #1b263b;
  color: white;
  padding: 0px 20px 80px 20px;
}

#colibri-footer {
  display: flex;
  padding-top: 80px;
}

.hover-white-underline:hover {
  opacity: 1 !important;
  text-decoration: underline !important;
}

#footer-brand-container {
  width: 30%;
  margin-right: 20px;
}

#footer-colibri-information-container {
  padding-top: 30px;
  width: 70%;
  display: flex;
  flex-wrap: wrap;
}

.footer-section {
  min-width: 130px;
  padding-left: 5px;
  padding-right: 60px;
}

.footer-section-title {
  color: white;
  font-weight: 550;
}

.footer-section-container {
  margin-top: 15px;
  margin-bottom: 45px;
  font-weight: lighter;
}

.footer-colibri-information-title {
  font-size: 1.3em;
}

.colibri-home-subsection {
  color: black;
  padding: 50px;
  width: 100%;
}

.margin-md {
  margin: 0px 20px 30px 20px;
}

.aligned-left-home {
  flex-direction: row-reverse;
}

.align-text-right-home {
  text-align: right;
}

.colibri-nav-dropdown {
  line-height: 18px;
}

#colibri-nav-hamburger {
  display: none;
}

ul#colibri-nav-right-list {
  list-style: none;
  margin-bottom: 0px;
}

#colibri-mobile-menu {
  display: none;
}

.heroku-and-salesforce-body {
  font-size: 0.9em;
}

.home-subsection-content-container {
  margin-top: 15px;
  display: flex;
  width: 100%;
  padding-bottom: 5px;
}

.aligned-center-home {
  margin-left: auto;
  margin-right: auto;
}

.colibri-subsection-content-container {
  display: flex;
  margin-bottom: 40px;
  width: 100%;
}

.home-subsection-subtitle {
  font-size: 1.8em;
  margin-bottom: 20px;
}

.colibri-subsection {
  flex: 1;
  padding: 20px 15px 20px 15px;
}

#colibri-security-text {
  width: 70%;
}

#security-icon-container {
  width: 30%;
}

.colibri-security-icon {
  max-width: 100%;
}

.colibri-subsection-centered {
  margin-bottom: 60px;
}

#heroku-and-salesforce {
  margin-top: 25px;
  width: 100%;
  display: flex;
  justify-content: left;
}

.heroku-and-salesforce-logo {
  text-align: center;
  height: 80px;
}

#joinus-page-container {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}

.heroku-and-salesforce-content {
  max-width: 300px;
  border-radius: 5px;
  margin: 8px;
  padding: 20px;
  background-color: #efefef;
}

.home-subsection-content {
  height: 100%;
  width: 50%;
  padding-top: 20px;
  font-size: 1.1em;
}

.responsive-img {
  max-width: 100%;
}

.shadowed-img {
  box-shadow: 0px 0px 30px 6px #bbb;
}

.flex-1 {
  flex: 1;
}

.add-margin-left-md {
  margin-left: 20px;
}

.ul-left > li {
  list-style-type: circle;
  text-align: left;
  padding-left: 0pt;
}

.list-style-p {
  margin-bottom: 0px;
}

.list-style-p::before {
  content: "•";
  color: left;
}

.colibri-subsection-icon {
  padding: 20px;
}

.colibri-home-subsection-title {
  display: block;
}

.colibri-homepage-subsection-title {
  color: #1b263b;
  font-weight: 600;
}

h2.colibri-home-subsection-small-title {
  display: block;
  font-size: 1.45em !important;
  color: #354553;
}

.colibri-home-subsection-title.title-light {
  color: #354553;
}

.colibri-home-subsection-title.title-sm {
  font-size: 1.4em;
}

.colibri-home-subsection-subtitle {
  font-size: 1.4em;
  color: #354553;
  margin-bottom: 12px;
}

.colibri-home-div {
  margin-bottom: 22px;
}

.colibri-sm-subsection-title {
  font-size: 1.9em;
  display: block;
  margin-bottom: 0;
}

.colibri-iframe-container {
  width: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.colibri-iframe {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.home-subsection-image-container {
  width: 50%;
  height: 100%;
  text-align: center;
}

.bloglike-subsection-image-container {
  width: 50%;
  height: 100%;
  text-align: left;
}

.colibri-merits-subsection {
  color: white;
  padding: 60px 10px 30px 10px;
}

.colibri-merits-subsection > div {
  margin-top: 30px;
}

.colibri-merits-subsection > div > p {
  font-size: 1rem;
  line-height: 1.6rem;
}

.align-text-center {
  text-align: center;
}

.colibri-merits {
  margin-top: 20px;
  font-size: 1.4em;
}

.colibri-about-us {
  padding: 20px 10px 60px 10px;
}

.colibri-about-us > p {
  text-align: center;
  font-size: 1.3em;
}

.founders-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-around;
}

.colibri-founder {
  max-width: 400px;
  margin-bottom: 30px;
  flex: 1;
}

.colibri-self-photo-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

p.founder-bio-important {
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 7px;
}

.founder-bio {
  padding: 10px;
}

.founder-fun-fact {
  background: linear-gradient(
    rgba(255, 242, 160, 0) 60%,
    rgba(252, 231, 234, 1) 60%,
    rgba(252, 231, 234, 1)
  );
  line-height: 0.2em;
}

.pink-underline {
  font-weight: bold;
  background: linear-gradient(
    rgba(255, 242, 160, 0) 60%,
    rgba(252, 231, 234, 1) 60%,
    rgba(252, 231, 234, 1)
  );
  line-height: 0.2em;
}

.colibri-tokutei-support {
  background-color: rgba(252, 231, 234, 1);
  margin: -12px;
  padding: 12px;
}

.home-page-text-box {
  padding: 60px 10px 60px 10px;
  width: 100%;
}

.home-page-text-box > div {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 60%;
}

.contact-us-button-container {
  padding: 20px 10px 60px 10px;
  text-align: center;
}

#blog-link-container {
  line-height: 40px;
}

#colibri-blog-link {
  margin-right: 20px;
  font-size: 0.9em;
  text-decoration: underline;
}

#shift-issue-details {
  font-size: 0.7em;
  max-width: 50%;
}

#presentation-images-banner {
  width: 45%;
  z-index: 1;
}

.colibri-home-text-container {
  max-width: 400px;
  color: #1b263b;
}

#colibri-logo {
  margin-left: 12px;
  margin-top: 3px;
}

#colibri-clients-banner {
  background: url("../images/colibri_clients_banner_alt.jpeg") repeat-x;
  background-size: 1983px 100px;
  height: 100px;
  width: 5949px;
  animation: slide 40s linear infinite;
  image-rendering: crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -moz-crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-1983px, 0, 0); /* The image width */
  }
}

.colibri-large-header-title {
  text-align: center;
  font-size: 2.2em;
  font-weight: bold;
  margin-bottom: 5px;
  color: #1b263b;
}

.colibri-issues-head-style {
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 5px;
  color: #1b263b;
}

.issue-header {
  margin-bottom: 10px;
}

#colibri-issues-parent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

#colibri-issues {
  display: flex;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.story-text-container {
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
}

.story-corporation-name,
.issue-body {
  margin-top: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
  font-size: 0.9em;
}

.story-additional-info {
  padding-top: 10px;
  padding-bottom: 30px;
  font-size: 0.8em;
}

.story-additional-info > p {
  margin-bottom: 7px;
}

.story-body {
  border-top: 1px dotted #ccc;
  margin-top: 9px;
  padding-top: 22px;
}

.story-information {
  margin-bottom: 8px;
  font-size: 0.8em;
}

.issue-header-title {
  text-align: center;
  font-size: 1.1em;
  font-weight: 550;
  color: #1b263b;
  margin-top: 15px;
}

.story-headline {
  text-align: left;
  font-size: 1em;
  font-weight: 550;
  min-height: 70px;
}

.issue-read-more {
  margin-top: 25px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

.colibri-issue-container {
  flex: 1;
  max-width: 300px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #1b263b;
  border-radius: 8px;
  background-color: #fcfcfc;
}

.story-container {
  flex: 1;
  max-width: 300px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  color: #1b263b;
  border-radius: 8px;
  background-color: white;
}

.story-container:hover {
  cursor: pointer;
  color: #17a2b8 !important;
}

.issue-icon {
  width: 60px;
}

.issue-container {
  flex: 1;
  max-width: 300px;
  background-color: white;
  border-radius: 20px;
  margin: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  color: #1b263b;
}

.issue-title {
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 20px;
  text-align: center;
}

.story-image {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.service-type-image {
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.story-main-image {
  border-radius: 20px !important;
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-right: 10px;
}

.image-subtext {
  font-size: 0.9em;
  color: #354553;
  margin-left: 10px;
  margin-right: 10px;
}

.colibri-arguments-wrapper {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.colibri-argument-container {
  margin: 10px;
  text-align: center;
}

.colibri-argument-container.service-type-container {
  background-color: #f7f9fe;
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.colibri-argument-container.service-type-container:hover {
  cursor: pointer;
}

.colibri-argument-container.four-items {
  width: 230px;
}

.colibri-argument-container.three-items {
  width: 310px;
}

.colibri-link-button-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.argument-title {
  color: #1b263b;
  font-weight: bold;
  font-size: 1.2em;
  margin-top: 15px;
  margin-bottom: 20px;
  text-align: center;
}

.argument-body {
  text-align: left;
}

.argument-illustration {
  display: flex;
  justify-content: center;
  align-items: middle;
  height: 100px;
}

.home-subsection-detailed-content {
  display: flex;
}

.colibri-sub-subsection-title {
  text-decoration: underline;
  font-size: 1.2em;
}
.colibri-subsection-line {
  margin-bottom: 10px;
  overflow: hidden;
}

.subsection-line-head {
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 100px);
}

.subsection-line-head:after {
  content: " ................................................................................................................................................................................";
}

.detailed-content-container {
  margin: 20px;
  flex: 1;
}

.detailed-content-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.3em;
  line-height: 35px;
  vertical-align: middle;
}

#colibri-nav-container {
  height: 100%;
  margin-left: 35px;
  font-size: 1em;
  display: flex;
  justify-content: left;
  align-items: center;
  color: #393e46;
}

.dropdown-toggle.colibri-nav-li {
  padding-right: 15px;
  padding-left: 5px;
}

.colibri-nav-li {
  height: 100%;
}

.colibri-hover-pink-underline {
  display: inline-block;
  position: relative;
}

.colibri-hover-pink-underline:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #ff9090;
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.colibri-hover-pink-underline:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.colibri-hover-pink-underline:hover {
  cursor: pointer;
}

.footer-element {
  display: block;
}

a.footer-url {
  line-height: 1em;
  color: #d9dce2;
  margin-bottom: 11px;
}

a.footer-url:hover {
  color: #d9dce2;
}

.hover-black:hover {
  color: black;
}

.colibri-vertically-centered-a {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
}

.nav-centered-vertically {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-centered-vertically > a {
  display: block;
  height: 59px;
  text-align: center;
  vertical-align: middle;
  line-height: 59px;
}

.colibri-bloglike-container {
  padding: 80px 10px 20px 10px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.colibri-bloglike-title {
  font-size: 2.4em;
}

.colibri-bloglike-p {
  margin-top: 20px;
  margin-bottom: 20px;
}

#colibri-bloglike-table-of-content-container {
  font-size: 1.2em;
  margin-top: 15px;
  border-radius: 15px;
  background-color: #f5f7f7;
  padding: 20px;
  display: inline-block;
}

#colibri-bloglike-table-of-content {
  text-decoration: underline;
  line-height: 1.6em;
}
#colibri-bloglike-table-of-content > div:hover {
  cursor: pointer;
}

.colibri-sm-h3-title {
  margin-top: 30px;
  margin-bottom: 5px;
  font-size: 1.5em;
}

.colibri-sm-h4-title {
  margin-top: 30px;
  margin-bottom: 5px;
  font-size: 1.2em;
}

.centered-btn-container {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.additional-information {
  font-size: 0.8em;
}

#colibri-covid-dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #ff9090;
  vertical-align: center;
  margin-bottom: 3px;
  margin-right: 2px;
}

#colibri-covid {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px 0px 0px 0px;
  border-radius: 18px;
  width: 320px;
  background-color: white;
  border: 0.5px solid #17a2b8;
}

#telework-image {
  width: 65%;
}

#colibri-covid-text {
  display: flex;
  padding-right: 30px;
  margin-bottom: 15px;
}

.margin-top {
  padding-top: 70px;
  margin-bottom: -20px;
}

#colibri-call-to-action-head {
  text-align: center;
  font-size: 2em;
  margin-bottom: 30px;
}

#colibri-call-to-action {
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  border-radius: 15px;
  border: 0.5px solid #17a2b8;
}

#colibri-call-to-action-body > p {
  font-size: 1.7em;
  text-align: center;
}

#colibri-thank-you {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 750px;
  border-radius: 15px;
  padding: 40px 30px;
  background-color: white;
  border: 0.5px solid #ccc;
}

#thank-you-container {
  padding-top: 45px;
  padding-bottom: 30px;
  display: flex;
}

#bottom-footer {
  font-size: 0.9em;
  opacity: 0.7;
}

@media screen and (orientation: portrait) {
  #blog-link-container {
    margin-top: 13px;
  }

  #colibri-banner-text {
    max-width: 90%;
  }

  .align-text-right-home {
    text-align: left;
  }

  .home-subsection-content {
    width: 100%;
    flex: 2;
  }

  .home-subsection-content-container {
    flex-direction: column;
  }

  .colibri-merits-subsection > div > p {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    line-height: 2em;
  }

  .home-page-text-box > div {
    max-width: 90%;
  }

  .founder-bio {
    margin-top: 30px;
    padding: 5px;
  }

  .founder-bio > p {
    line-height: 1.3em;
  }

  .home-subsection-image-container {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
