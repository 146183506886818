.mb-assessment-container {
    border-radius:4px;padding:4px;border:1px solid #ccc;margin-bottom:12px
}

.mb-assessment-header {
    display:flex;justify-content:space-between;line-height:32px;
}

.mb-document-title {
    color:#5f6368;font-size:1.05em
}

.mb-assessment-body {
    border-top:1px solid #ccc
}

.mb-assessment-content {
    background-color:#defcfc;margin-top:4px;padding:5px;border-radius:5px;font-size:0.9em
}

.mb-document-divider {
    text-align:center;font-size:11px;margin-top:5px
}

.red-text {
    color: #ED2939;
}