body {
  background-color: #fff;
  color: #333;
  margin: 33px;
  font-family: "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ",
    "Meiryo", "MS Pゴシック", "IPAexゴシック", "IPA Pゴシック", verdana, arial,
    helvetica, sans-serif !important;
  font-size: 13px;
  line-height: 18px;
}

td {
  font-size: 13px;
}

pre {
  background-color: #eee;
  padding: 10px;
  font-size: 11px;
}

th {
  padding-bottom: 5px;
}

td {
  padding: 0 5px 7px;
}

div.field,
div.actions {
  margin-bottom: 10px;
}

#notice {
  color: green;
}

.field_with_errors {
  padding: 2px;
  background-color: red;
  display: table;
}

#error_explanation {
  width: 450px;
  border: 2px solid red;
  padding: 7px 7px 0;
  margin-bottom: 20px;
  background-color: #f0f0f0;
}

#error_explanation > ul,
#error_explanation > li {
  font-size: 12px;
  list-style: square;
}
#error_explanation > h2 {
  text-align: left;
  font-weight: bold;
  padding: 5px 5px 5px 15px;
  font-size: 12px;
  margin: -7px -7px 0;
  background-color: #c00;
  color: #fff;
}

label {
  display: block;
}
