.comment-container {
  width: 100%;
}

.commentor-and-thread-container {
  width: 45px;
}

.commenter-top-thread {
  height: 26px;
  width: 22.5px;
  border-right: 1px solid #ccc;
  color: transparent;
}

.commenter-bottom-thread {
  height: calc(100% - 50px);
  width: 22.5px;
  border-right: 1px solid #ccc;
  color: transparent;
}

.comment-head {
  height: 18px;
  line-height: 15px;
}

.comment-body {
  width: calc(100% - 49px);
}

.coli-edited-label {
  margin-left: -10px;
}
