.hide-resource {
  display: none;
}

.patients-submenu {
  display: flex;
  justify-content: space-between;
}

#patient-dashboard {
  height: 165px;
  display: flex;
}

.patient-dashboard-panel {
  border: 0.5px solid #ccc;
  border-radius: 3px;
  width: calc(50% - 5px);
}

#payable-menu {
  display: none;
}

.patient-left-panel {
  width: calc(75% - 5px);
  margin-right: 5px;
}

.patient-right-panel {
  width: 34%;
}

.patient-right-panel > div.patient-dashboard-panel-body {
  overflow-y: auto;
}

.patient-dashboard-panel-title {
  margin: 3px;
  border-bottom: 0.5px solid #ccc;
}

.patient-dashboard-panel-body {
  height: 132px;
  overflow-y: hidden;
  overflow-x: auto;
  margin: 3px;
}

.attendance-dashboard-panel {
  overflow-x: auto;
  margin: 3px;
}

.patient-management-item:hover {
  background-color: #f4f7f7;
  color: #828e99;
}

.patient-management-item.selected {
  color: #828e99;
  border-bottom: 3px solid rgb(122, 213, 222);
}

.kana-header {
  padding: 6px 10px 6px 10px;
  border-bottom: 0.5px solid #dee2e6;
  font-weight: bold;
  font-size: 1.1em;
}

.kana-toggle-arrow {
  float: right;
}

.assessment-delimiter {
  padding-right: 6px;
  border-right: 2px solid #ddd;
  height: 100%;
  width: 100%;
}

/* patient office contract page ; thread */

.selected-thread-dot {
  margin-bottom: 3px;
  margin-right: 5px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #7ad5de;
  display: inline-block;
}

.selected-thread-dot.dot-container {
  display: none;
}

.selected-thread-dot.dot-container.show-dot {
  display: inline-block;
}

#contract-thread-body {
  overflow: hidden;
}

.contract-thread-item {
  display: flex;
  width: 100%;
  font-size: 0.9em;
  page-break-inside: avoid !important;
}

.thread-item-body {
  margin: 5px 0px 5px 15px;
  padding: 6px 8px 6px 8px;
  border: 1px solid #e0e2e2;
  border-radius: 3px;
  width: 100%;
  display: flex;
}

.reportable-information,
.post-information {
  width: 23%;
}

.report-items,
.post-readmarks {
  width: 17%;
}

.report-body,
.post-content {
  width: 50%;
}

.report-action,
.post-action {
  width: 10%;
}

.mobile-thread-item-body {
  margin: 5px;
  padding: 6px 8px 6px 8px;
  border: 1px solid #e0e2e2;
  border-radius: 3px;
  width: calc(100% - 10px);
}

.thread-item-head {
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  padding-bottom: 3px;
  border-bottom: 1px solid #eff1f1;
}

.thread-block {
  padding-left: 3px;
  padding-right: 3px;
}

.thread-post-body {
  background-color: #fafbfb;
  padding: 8px;
  border: 0.5px solid #ccc;
  border-radius: 5px;
}

.pdca-buttons {
  flex-direction: row;
  min-width: 62.52px;

  @media screen and (min-width: 1200px) {
    flex-direction: column;
  }
}

.new-pdca-button {
  justify-content: end;

  @media screen and (min-width: 1200px) {
    flex-direction: row-reverse;
    height: fit-content;
  }
}

.assessment-cell {
  flex-direction: column-reverse;
  justify-content: start;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.assessment-details {
  flex-grow: 1;
  margin-right: 20px;
}

.assessments-column {
  width: 31%;
}

.service-plannings-column {
  width: 43%;
}

.monitorings-column {
  width: 26%;
}

.assessments-table-cell {
  height: 150px;
}

.sort-thread-btn {
  padding-left: 7px;
  padding-right: 7px;
}

@media print {
  .thread-item-body {
    overflow: hidden;
  }

  .comments-container {
    overflow: hidden;
  }
}