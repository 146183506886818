@media screen and (orientation: portrait) and (min-width: 760px) and (max-width: 1020px) {
  #top-subsection {
    padding: 80px 30px 70px 30px;
    justify-content: space-between;
    margin-bottom: -60px !important;
  }

  #top-subsection.handicap-top-section {
    height: 350px !important;
    margin-bottom: 10px !important;
  }

  #top-subsection.product-top-section {
    padding-bottom: 20px;
    background-position: 0% 40%;
  }

  #handicap-image-container {
    padding-top: 40px;
  }

  #main-image-responsive {
    display: none !important;
  }

  #main-image {
    display: block !important;
    margin-left: -100px;
  }

  h2.colibri-home-presentation-title {
    width: 120%;
  }

  #presentation-images-banner {
    padding-top: 30px;
    width: 33%;
  }

  #presentation-text {
    padding: 0;
    width: 62%;
  }

  .colibri-home-subsection {
    padding: 50px 20px 30px 20px;
  }

  .home-subsection-content.margin-md {
    margin: 0px;
    padding: 20px;
  }

  #colibri-issues {
    flex-wrap: wrap;
    justify-content: center;
  }

  #issues-subsection {
    padding-left: 10px;
    padding-right: 10px;
  }

  .issue-container {
    margin: 10px;
    min-width: 300px;
  }

  .nav-tablet-hidden {
    display: none;
  }

  #heroku-and-salesforce {
    flex-direction: column;
  }

  .detailed-content-container {
    margin: 15px;
  }

  #colibri-contact-form-container {
    margin-right: 10px;
  }

  #contact-form-image-container {
    max-width: 280px;
  }

  #colibri-batch-action-button {
    height: 65px;
    width: 65px;
    border-radius: 65px;
  }

  span.glyphicon.glyphicon-pencil.batch-action-pencil {
    line-height: 45px;
    font-size: 1.2em;
    margin-bottom: -5px;
  }

  .story-container {
    margin: 10px;
    margin-bottom: 25px;
  }

  .colibri-homepage-subsection-title {
    text-align: center;
    font-size: 2em;
  }

  .colibri-homepage-subsection-title.add-margin-left-md {
    margin-left: 0px;
  }

  #colibri-home-pink-pattern-5 {
    margin-top: -65vw;
  }

  #calendar-main-image {
    display: none !important;
  }

  #calendar-tablet-image {
    display: block !important;
  }

  .iphone-vertical-image {
    max-height: 350px !important;
    margin-top: 40px;
  }

  .attendance-summary-container {
    width: 100%;
  }

  .attendance-action-container {
    flex-direction: column-reverse;
  }

  .attendance-date-query-container {
    justify-content: left !important;
  }

  .reaction {
    min-width: 21px;
  }
}
