.post-container {
  padding: 5px;
}

.post-header {
  display: flex;
  justify-content: space-between;
}

.post-author-wrapper {
  font-size: 0.85em;
}

.post-timestamps-wrapper {
  color: grey;
  font-size: 0.85em;
}

.post-body {
  background-color: #defcfc;
  border-radius: 5px;
  padding: 3px 7px 5px 7px;
}

div.post-body > p {
  font-size: 0.85em;
}

.post-body.unread-post {
  background-color: #beffb7;
}

.post-body.unread-post.reminderable-post {
  background-color: #ffcad4;
}

.post-clickable-row:hover {
  cursor: pointer;
}

.unrepresentable-wrapper {
  width: 80px;
  height: 80px;
  border: 1.5px solid #eaecec;
  display: inline-block;
}

.unrepresentable-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.extension-icon-container {
  display: flex;
  align-items: center;
  padding-left: 2px;
}

.unrepresentable-filename {
  overflow-wrap: anywhere;
  font-size: 0.8em;
  height: 60px;
}

.unrepresentable-info-bar {
  background-color: #eaecec;
  height: 20px;
  display: flex;
  justify-content: space-between;
}

.extension-icon {
  font-size: 9px;
  color: white;
  padding: 2px 3px;
  height: 13px;
  line-height: 9px;
  border-radius: 3px;
}

.xls-extension {
  background-color: #1fa463;
}

.pdf-extension {
  background-color: #f40f02;
}

.ppt-extension {
  background-color: #ffd04b;
}

.doc-extension {
  background-color: #4688f4;
}

.default-extension {
  background-color: #5f6368;
}

.coli-thread-line {
  height: 10px;
  width: 22.5px;
  border-right: 1px solid #ccc;
  color: transparent;
}

#read-unread-menu {
  border-bottom: 2px solid #ccc;
}

.read-mark-container {
  line-height: 40px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.read-mark-container > .user-name {
  line-height: 45px;
}

@media print {
  table#post-table > tbody::after {
    content: "";
    display: block;
    page-break-after: always !important;
    page-break-inside: avoid;
    page-break-before: avoid;
  }

  tbody.break-page-on-print {
    break-after: page !important;
    page-break-after: always !important;
  }

  tr.post-clickable-row {
    page-break-inside: avoid !important;
  }

  td.td-post-body {
    page-break-inside: avoid !important;
  }

  .thread-post-body {
    page-break-inside: avoid !important;
  }

  #read-posts-container {
    width: 100% !important;
  }
}
