/* app-wise css rules */

a {
  text-decoration: none !important;
}

#remote_container {
  width: 100vw;
  overflow-x: hidden;
}

.btn-sm-fixed-height {
  height: 33.5px;
}

/* Image-related styles */

.colibri-play-icon {
  position: absolute;
  left: 34px;
  top: 27px;
  opacity: 0.9;
}

.file-names-container {
  margin-left: 12px;
  font-size: 14px;
  color: #4f5b66;
  line-height: 30px;
  overflow-x: hidden;
  width: calc(100% - 157px);
}

.report-image {
  margin: 5px 5px 0px 0px;
}

.report-video {
  position: relative;
  display: inline-block;
}

.report-pictures-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.report-picture-container {
  margin-bottom: 5px;
  margin-right: 5px;
}

.photo-preview-container {
  height: 62px;
  width: 62px;
  border-radius: 62px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

#placeholder-patient-image {
  width: 62px;
  height: 62px;
  border-radius: 62px;
  background-color: #7ad5de;
  color: white;
  font-size: 1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
}

#patient-thumb {
  border-radius: 62px;
  border: 1px solid #ccc;
}

.patient-name-bubble {
  width: 62px;
  height: 62px;
  border-radius: 62px;
  background-color: #7ad5de;
  color: white;
  font-size: 1.3em;
  vertical-align: middle;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  vertical-align: middle;
}

.colibri-photo {
  max-width: 100%;
  max-height: 100%;
}

input[type="file"]::file-selector-button {
  background-color: #eaecec;
  border: 2px solid #eaecec;
  color: #5f6368;
  border-radius: 4px;
}

input[type="file"]::file-selector-button:hover {
  background-color: #e2e4e4;
  border: 2px solid #e2e4e4;
  color: #5f6368;
  cursor: pointer;
}

/* Bootstrap 5 adjustments */
input.form-check-input {
  border: 1.5px solid #bbb;
}

input.form-check-input {
  border: 1.5px solid #bbb;
}

.coli-cyan-checkbox.form-check-input:checked {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.coli-green-checkbox.form-check-input:checked {
  background-color: #30af4d !important;
  border-color: #30af4d !important;
}

.coli-green-checkbox.form-check-input:focus {
  box-shadow: 0 0 3px 3px rgba(40, 167, 69, 0.8);
  border-color: #30af4d !important;
}

.modal {
  overflow-y: auto !important;
}

.btn.btn-info {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: white;
}

.btn.btn-info:hover {
  background-color: #138496 !important;
  border-color: #138496 !important;
  color: white !important;
}

.btn.btn-info:active {
  background-color: #138496 !important;
  border-color: #138496 !important;
  color: white !important;
  box-shadow: 0 0 0 0.25rem rgb(11 172 204 / 50%);
}

.btn.btn-info:focus {
  background-color: #138496 !important;
  border-color: #138496 !important;
  color: white !important;
}

.btn.btn-outline-info {
  background-color: transparent;
  border-color: #17a2b8;
  color: #17a2b8;
}

.btn.btn-outline-info:hover {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
  color: white !important;
}

.form-check-input.input-success:checked {
  background-color: #30af4d;
  border-color: #30af4d;
  color: white;
}

.btn.btn-success {
  background-color: #30af4d;
  border-color: #30af4d;
  color: white;
}

.btn.btn-success:hover {
  background-color: #299040 !important;
  border-color: #299040 !important;
  color: white !important;
}

.btn.btn-success:active {
  background-color: #299040 !important;
  border-color: #299040 !important;
  color: white !important;
  box-shadow: 0 0 0 0.25rem rgb(72 180 97 / 50%);
}

.btn.btn-success:focus {
  background-color: #299040 !important;
  border-color: #299040 !important;
  color: white !important;
}

table.table > thead,
table.table > thead > tr,
table.table > thead > tr > th {
  border-bottom: 2px solid #dee2e6 !important;
  border-top: 1px solid #dee2e6 !important;
}

table.table > thead > tr > th,
table.table > tbody > tr > td,
table.table > tbody > tr > th {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.popover-head,
.popover-header {
  padding: 6px 8px 6px 8px !important;
}

.popover-body {
  padding: 6px 8px 6px 8px !important;
}

/*  */

.glyphicon.glyphicon-cog:hover,
.glyphicon.glyphicon-home:hover,
.glyphicon.glyphicon-calendar:hover {
  color: white !important;
}

.glyphicon.glyphicon-calendar.glyphicon-no-hover-style:hover {
  color: inherit;
}

.button:focus,
.btn:focus {
  outline: none !important;
  box-shadow: none;
}

.coli-clickable-row:hover {
  cursor: pointer;
}

.ripple {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  border-radius: inherit;
  pointer-events: none;
  animation: ripple-shadow 0s forwards;
  -webkit-animation: ripple-shadow 0s forwards;
}

.rippleWave {
  backface-visibility: hidden;
  position: absolute;
  border-radius: 50%;
  transform: scale(0.7);
  -webkit-transform: scale(0.7);
  background: rgba(255, 255, 255, 0.2);
  opacity: 0.45;
  animation: ripple 1s forwards;
  -webkit-animation: ripple 1s forwards;
}

.stick-to-top {
  position: fixed !important;
  top: 20px;
}

.recent-reports-container {
  border-top: 0.5px solid #eee;
  padding-top: 4px;
}

html.touch *:hover {
  pointer-events: none !important;
  all: unset !important;
}

tr.clickable-row:hover {
  background-color: #eff1f1 !important;
}

/* Pagy styles */

.page-item.active > a.page-link {
  color: #354553 !important;
  background-color: #dee2e6 !important;
  border-color: #dee2e6 !important;
}

.page-link {
  color: #354553 !important;
}

/* Colibri forms */

.clickable-switch {
  width: 30px;
  height: 17px;
  border-radius: 17px;
  padding-top: 2px;
  margin-top: 5px;
}

.clickable-switch.switch-on {
  background-color: #17a2b8;
}

.clickable-switch.switch-off {
  background-color: #ccc;
}

.clickable-switch-slider {
  height: 13px;
  width: 13px;
  border-radius: 13px;
  background-color: white;
}

.clickable-switch-slider.switch-on {
  margin-left: 15px;
}

.clickable-switch-slider.switch-off {
  margin-left: 2px;
}

.colibri-form-info {
  background-color: #e7fdfd;
  color: #046985;
  border: 1px solid #046985;
  font-size: 0.9em;
  border-radius: 4px;
  padding: 6px;
}

.colibri-form-info.inline-info {
  display: inline;
}

.colibri-form-secondary {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #5f6368;
  color: #5f6368;
  font-size: 0.9em;
  border-radius: 4px;
  padding: 6px;
}

.colibri-form-warning {
  background-color: #fdf2a0;
  color: #6d6109;
  border: 1px solid #6d6109;
  font-size: 0.9em;
  border-radius: 4px;
  padding: 6px;
}

.colibri-form-success {
  background-color: #e6facc;
  color: #476b43;
  border: 1px solid #476b43;
  font-size: 0.9em;
  border-radius: 4px;
  padding: 6px;
}

.colibri-form-success.inline-success {
  display: inline;
}

.colibri-form-alert {
  background-color: #fdb5bd;
  border: 1px solid #c2838a;
  color: #531e24;
  font-size: 0.9em;
  border-radius: 4px;
  padding: 6px;
}

#admin-corporation-name-container {
  position: fixed;
  z-index: 2000;
  font-size: 0.9em;
  top: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 5px 17px;
  background-color: #e6facc;
  color: #476b43;
  border-radius: 4px;
}

#admin-corporation-name-container:hover {
  cursor: pointer;
}

#attendance-menu-icon {
  padding-bottom: 15px;
}

.ipad-no-flex {
  display: flex;
}

.ipad-only-display,
.badge.ipad-only-display {
  display: none !important;
}

.colibri-filter-btn-sm {
  border-radius: 14px;
  height: 28px;
  padding: 3px 9px 3px 9px;
  border: 1px solid transparent;
  line-height: 20px;
  font-size: 0.9em;
}

.colibri-filter-btn-sm.icon-filter {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.colibri-thread-line {
  height: 100%;
  width: 2px;
  background-color: #eff1f1;
  margin-left: auto;
  margin-right: auto;
}

.colibri-thread-icon-container {
  margin-top: 10px;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: #eff1f1;
  text-align: center;
  vertical-align: middle;
  padding-top: 6px;
}

.medical-reports-label {
  width: 160px;
}

.filter-selected.fc-button.fc-button-primary {
  background-color: #d5d5d5 !important;
}

.video-play-button {
  margin-left: -48px;
  margin-right: 32px;
  opacity: 0.85;
  width: 16px;
}

.video-play-button:hover {
  cursor: pointer;
}

.direct-upload {
  display: inline-block;
  position: relative;
  padding: 2px 4px;
  margin: 0 3px 3px 0;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  font-size: 11px;
  line-height: 13px;
}

.direct-upload--pending {
  opacity: 0.6;
}

.direct-upload__progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0.2;
  background: #7ad5de;
  transition: width 120ms ease-out, opacity 60ms 60ms ease-in;
  transform: translate3d(0, 0, 0);
}

.direct-upload--complete .direct-upload__progress {
  opacity: 0.4;
}

.direct-upload--error {
  border-color: red;
}

input[type="file"][data-direct-upload-url][disabled] {
  display: none;
}

.colibri-yellow-warning {
  color: rgb(255, 223, 92);
  font-size: 11px;
}

.coli-ripple:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: white;
  visibility: hidden;
  z-index: 2;
}

.coli-ripple:not(:active):before {
  animation: colibri-ripple 0.4s cubic-bezier(0, 0, 0.2, 1);
  transition: visibility 0.4s step-end;
}

.coli-ripple:active:before {
  visibility: visible;
}

.anim-scale-in {
  animation-name: scale-in;
  animation-duration: 0.15s;
  animation-timing-function: cubic-bezier(0.2, 0, 0.13, 1.5);
}

@keyframes colibri-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0.5;
  }

  100% {
    width: 150px;
    height: 150px;
    opacity: 0;
  }
}

@keyframes ripple {
  to {
    transform: scale(24);
    opacity: 0;
  }
}

@keyframes ripple-shadow {
  0% {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  20% {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
  }
  100% {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
}

@-webkit-keyframes ripple {
  to {
    -webkit-transform: scale(24);
    opacity: 0;
  }
}

@-webkit-keyframes ripple-shadow {
  0% {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  20% {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.3);
  }
  100% {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
}

textarea {
  field-sizing: content;
  min-height: 4em !important;
}