@media screen and (orientation: landscape) and (max-width: 900px) {
  /* login styles */

  #flash {
    position: fixed !important;
    top: 0px !important;
    z-index: 35;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  input:focus,
  select:focus,
  textarea:focus {
    font-size: 16px;
  }

  .devise-input {
    font-size: 16px;
  }

  /* Home page layouts */

  #top-subsection {
    flex-direction: column;
    max-height: none;
    height: 700px;
  }

  #top-subsection.product-top-section.handicap-top-section {
    height: auto !important;
  }

  #top-subsection.product-top-section {
    background-position: bottom;
  }

  #colibri-home-pink-pattern {
    display: none;
  }

  #colibri-home-pink-pattern-smartphone {
    display: none;
  }

  #colibri-home-pink-pattern-smartphone-landscape {
    margin-top: -300px;
    display: block !important;
  }

  #presentation-text {
    padding-top: 0px;
    width: 100%;
  }

  #presentation-images-banner {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    z-index: 3;
  }

  #calendar-main-image {
    width: 470px;
  }

  .iphone-vertical-image {
    height: 330px !important;
  }

  #service-planning-top-image {
    height: 330px !important;
  }

  #main-image {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  #colibri-main-image {
    width: 500px;
  }

  h2.colibri-home-presentation-title {
    text-align: center;
  }

  .colibri-home-subsection {
    padding: 45px;
  }

  .issue-container {
    max-width: none;
  }

  .issue-body {
    padding: 0 15px;
  }

  .home-subsection-detailed-content {
    flex-direction: column;
  }

  .colibri-subsection-content-container {
    flex-direction: column;
    margin: 0;
  }

  .colibri-subsection {
    padding: 25px 0;
  }

  /* general app-like styles */

  ::-webkit-scrollbar {
    display: none !important;
  }

  .turbolinks-progress-bar {
    visibility: hidden;
  }

  .btn.btn-desktop {
    display: none;
  }

  .btn.btn-phone-expand {
    width: 100%;
  }

  .phone_activity_container {
    z-index: 100;
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: scroll;
    background-color: white;
  }

  .activity_body {
    width: 100%;
    height: 100%;
  }

  .activity_header {
    height: 48px;
    width: calc(100% + 10px);
    margin-left: -5px;
    line-height: 48px;
    padding-left: 12px;
    padding-right: 12px;
    background-color: #2b384e;
    color: white;
    display: flex;
  }

  .activity_header_responsive {
    width: calc(100% - 50px);
    margin-left: 50px;
  }

  .activity_back_button {
    line-height: 48px;
    margin-right: 12px;
  }

  .activity_title {
    font-size: 1.3em;
    line-height: 48px;
  }

  #planning-top-right-action-container {
    display: none;
  }

  /* navigation styles */

  .main-page {
    padding-top: 0px !important;
  }

  #planning-menu-container {
    height: 100%;
    padding-top: 0px;
    background-color: #fdfdfd;
    border-right: 0.5px solid #ccc;
  }

  .glyphicon-default {
    color: rgb(180, 190, 199);
  }

  .glyphicon-selected {
    color: rgb(115, 120, 124);
  }

  li.planning-menu-items {
    color: rgb(180, 190, 199);
  }

  #more-menu-items-list {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
    padding: 0px;
    margin-bottom: 0px;
  }

  #more-planning-menu-items {
    bottom: 18px;
    left: 68px;
  }

  .planning-menu-items.more-items {
    margin-left: 15px;
    margin-right: 15px;
    text-align: center;
    height: 58px;
  }

  #planning-menu-list {
    height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  ul.nav-tabs {
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  li.planning-menu-items {
    display: block;
  }

  #planning-horizontal-menu-container {
    display: none;
  }

  #phone-reflect-button-wrapper {
    bottom: 35px;
  }

  #colibri-nav-container {
    display: none;
  }

  #colibri-nav-right-list {
    display: none;
  }

  #colibri-mobile-nav {
    display: block;
    margin-right: 20px;
  }

  #close-nav-mobile {
    font-size: 3.5em;
    padding-bottom: 7px;
  }

  #colibri-mobile-menu {
    border-top: 2px solid #ccc;
    border-left: 2px solid #ccc;
    position: fixed;
    top: 53px;
    right: 0;
    height: calc(100vh - 50px);
    width: 50vw;
    z-index: 30;
    background-color: white;
    padding: 7px 15px 7px 15px;
    overflow-y: auto;
  }

  #mobile-only-contact-form-button {
    display: block;
  }

  #mobile-menu-call-to-action {
    font-size: 1.1em;
  }

  .menu-item {
    border-bottom: 2px dotted #ccc;
  }

  .menu-item-title {
    display: block;
    padding: 10px;
    width: 100%;
    font-size: 1.1em;
    font-weight: 350;
  }

  .menu-sub-item {
    margin-left: 20px;
    padding: 4px;
  }

  .glyphicon.glyphicon-cog:hover,
  .glyphicon.glyphicon-home:hover,
  .glyphicon.glyphicon-calendar:hover {
    color: rgb(115, 120, 124) !important;
  }

  /* Contact form layouts */

  #contact-form-background {
    padding: 80px 0 40px 0;
    height: auto;
  }

  #colibri-contact-form-container {
    display: block;
    position: relative;
    margin-right: 10px;
  }

  #colibri-thank-you {
    margin: 10px;
  }

  /* Fullcalendar layouts */

  .fc-zoomOut-button,
  .fc-zoomIn-button {
    display: none !important;
  }

  .responsive-landscape-only {
    display: inline;
  }

  table.fc-list-table {
    border: none;
  }

  tr.fc-list-heading {
    border: none;
    border-bottom: 1px solid #ccc;
  }

  tr.fc-list-heading > td.fc-widget-header {
    background-color: white;
    text-align: center;
    border: none;
  }

  .fc-list-heading-main {
    font-size: 1.3em;
  }

  .colibri-fc-list-header {
    margin: 0;
    display: flex;
    justify-content: space-between;
    color: black;
    font-size: 1.1em;
  }

  .colibri-fc-list-title {
    font-size: 1.3em;
    line-height: 1.3em;
  }

  .fc-view.fc-listDay-view.fc-list-view {
    border: none;
  }

  div.colibri-fc-list {
    margin: 4px 0px 4px 0px;
    padding: 4px 12px 6px 12px;
    border-radius: 3px;
    color: black;
  }

  /* calendar layout styles */

  #planning-page-wrapper {
    width: calc(100vw - 58px);
    left: 58px;
    padding: 0px;
  }

  #detail-planning-container {
    width: 100%;
    margin: 0px 0px 150px 0px;
    padding-top: 0px;
  }

  #completion-reports-container {
    margin-left: 0px;
  }

  #detail-planning-title {
    justify-content: space-between;
    line-height: 48px;
    height: 48px;
    margin-bottom: 5px;
  }

  #planning-header {
    width: calc(100% + 10px);
    margin-left: 0px;
    padding-left: 0px;
    box-shadow: 0 4px 2px -2px #ccc;
    margin-bottom: 10px;
    display: block;
    background-color: #2b384e;
    color: white;
    border-bottom: 0.3px solid #2b384e;
  }

  #resource-detail-header {
    display: none;
  }

  #resource-details-wrapper {
    width: 100%;
  }

  #nurse_filter_wrapper {
    min-width: 0px !important;
    margin-right: 5px;
  }

  #nurse_filter_wrapper > .selectize-control {
    width: auto;
  }

  #nurse_resource_filter {
    min-width: none;
  }

  #resource-details-button {
    display: none !important;
  }

  #planning-header-submenu {
    display: flex;
  }

  .header-submenu-item {
    text-align: center;
    flex-grow: 1;
    color: rgba(255, 255, 255, 0.5);
  }

  .header-submenu-item.header-submenu-item-selected {
    color: white;
    border-bottom: 3px solid white;
  }

  #close-resource-list {
    display: block;
    text-align: right;
    font-size: 1.2em;
    color: white;
  }

  #toggle-patients-nurses-wrapper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .selectize-input {
    white-space: nowrap;
  }

  #resource-list-container {
    background-color: #415465 !important;
    height: 100%;
    min-width: 150px;
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    overflow-x: hidden;
    padding-top: 3px;
    padding-bottom: 60px;
    display: none;
  }

  #menu-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    height: 100vh;
    width: 100vw;
    background-color: black;
    opacity: 0.6;
  }

  #resource-container {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hide;
    padding-bottom: 40px;
  }

  #resource-name-wrapper {
    padding-right: 5px;
    padding-top: 0px;
    padding-left: 6px;
    margin-bottom: 5px;
    line-height: 48px;
    font-size: 1.3em;
    font-weight: normal;
    color: white;
  }

  #nurses-resource {
    padding-bottom: 40px;
  }

  #patients-resource {
    padding-bottom: 40px;
  }

  #planning-nurse-information {
    display: flex;
  }

  #close-reports-index {
    display: none;
  }

  #display-completion-reports {
    display: none !important;
  }

  /* Hide batch action button */

  #colibri-batch-action-button {
    height: 60px;
    width: 60px;
    border-radius: 60px;
  }

  .batch-action-button {
    bottom: 35px;
    right: 35px;
  }

  span.glyphicon.glyphicon-pencil.batch-action-pencil {
    line-height: 45px;
    font-size: 1.2em;
    margin-bottom: -5px;
  }

  /* Hide print button */
  #print-button {
    display: none;
  }

  /* container styles */

  #payable-container {
    width: calc(100vw - 58px);
    top: 0px;
    padding-left: 0px;
    margin-top: 0px;
    margin-left: 58px;
    height: 100vh;
  }

  #detail-container {
    display: block;
    width: 100%;
    margin-bottom: 0px;
    padding: 0px;
  }

  ul#left-menu-ul {
    justify-content: space-between;
  }

  ul#left-menu-ul.align-left {
    justify-content: start !important;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  ul#left-menu-ul.align-left > li.planning-menu-items {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  span.coli-bottom-menu-icon {
    line-height: 48px;
  }

  /* payable view styles */

  #resource-list-container {
    display: none;
  }

  #payable-menu {
    display: block;
  }

  .patient-left-panel {
    width: 100%;
  }

  .payable-body {
    margin-top: 10px;
  }

  #payable-title-wrapper {
    width: calc(100% + 10px);
    margin-left: -5px;
    height: 48px;
    z-index: 9;
    background-color: #2b384e;
    line-height: 48px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .colibri-xs-title.colibri-payable-title {
    font-size: 1.3em;
    font-weight: normal;
    color: white;
    line-height: 48px;
  }

  .patient-right-panel {
    display: none;
  }

  #payable-query {
    display: block;
    margin-top: 10px;
  }

  #appointments-by-category-detail {
    display: none;
  }

  #detail-planning-title {
    justify-content: left;
  }

  #payable-title {
    margin-top: 5px;
  }

  #payable-query {
    padding-left: 12px;
  }

  .planning-activity-module-title {
    padding-left: 4px;
  }

  .forty-percent-width {
    width: calc(100% - 10px);
  }

  .sixty-percent-width {
    width: calc(100% - 10px);
  }

  /* dashboard layout */

  .colibri-dashboard-container-row {
    overflow-x: auto;
    height: 70vh;
  }

  #posts-index-container {
    margin-left: 50px;
    width: calc(100vw - 50px);
  }

  /* settings page */

  #patients-excel {
    display: none;
  }

  .hamburger_menu {
    display: inline-block;
    margin-right: 6px;
  }

  #logout {
    display: inline-block;
  }

  p.setting-title {
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 48px;
    font-weight: normal;
    font-size: 1.3em;
  }

  #settings-menu-container {
    height: 100%;
    width: 200px;
    position: fixed;
    z-index: 30;
    top: 0px;
    left: 0px;
    padding-top: 30px;
    background-color: #415465 !important;
    border: 0.5px solid #415465;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    display: none;
  }

  #settings-container {
    margin: 0px 0px 0px 58px;
    width: calc(100% - 58px);
    padding: 0px 5px 5px 5px;
    position: absolute;
    left: 0 !important;
    padding: 0px;
  }

  #planning-header-container {
    padding-top: 0px;
    padding-right: 0px;
  }

  #settings-menu-list {
    margin-top: 0px;
  }

  #index-table-container {
    margin-top: 0px;
  }

  .edit_corporation {
    margin-bottom: 70px;
  }

  .edit_printing_option {
    margin-bottom: 70px;
  }

  #planning-options {
    max-width: none;
    width: 100%;
    margin-bottom: 0px;
  }

  #printing-options {
    max-width: none;
  }

  /* Completion Reportslayouts */

  #patient-completion-reports-body {
    margin-top: 15px;
  }

  #handicap-image-container {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}
