#admin_page_content {
  position: fixed;
  left: 240px;
  top: 0;
  width: calc(100vw - 240px);
  height: 100vh;
  z-index: -20;
}

#admin_page_head {
  padding-top: 12px;
  border-bottom: 2px solid #eee;
}

#admin_page_title {
  margin-left: 5px;
  padding-left: 12px;
  font-size: 1.4em;
  font-weight: bold;
  margin-top: 15px;
}

.coli-data-menu-link {
  display: block;
}

.coli-data-menu-link:hover {
  background-color: #fcfcff;
}

.coli-data-menu-item {
  border-bottom: 1px solid #ccc;
  padding: 3px;
}

.coli-data-menu-item.selected {
  background-color: #fcfcff;
}

#admin_page_body {
  background-color: #f0f1f3;
  height: calc(100vh - 80px);
  overflow-y: auto;
}

#admin_page_tab_container {
  margin-top: 15px;
  display: flex;
  margin-bottom: -1px;
}

.admin-page-tab {
  color: #505d6f;
  width: 130px;
  text-align: left;
  padding: 5px;
}

.admin-page-tab.selected {
  border-bottom: 2px solid #505d6f;
}

.admin-data-block {
  display: flex;
  width: calc(100% - 150px);
  height: 350px;
}

.admin-data-container {
  margin: 8px;
  padding: 7px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
}

.admin-width-40 {
  width: calc(40% - 16px);
}

.admin-width-60 {
  width: calc(60% - 16px);
}

.admin-data-header {
  padding-bottom: 2px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  height: 31px;
}

.admin-data-content {
  overflow-y: auto;
  height: 293px;
}

.nav_top_item {
  color: #505d6f;
  font-size: 1em;
  margin: 5px;
}

.coli_admin_link {
  display: inline-block;
}

.fa-admin-left-nav {
  font-size: 17px;
}

.fa-admin-left-nav-sm {
  font-size: 14px;
}
