@media screen and (orientation: portrait) and (max-width: 760px) {
  /* login styles*/

  input:focus,
  select:focus,
  textarea:focus {
    font-size: 16px;
  }

  #flash {
    position: fixed !important;
    top: 0px !important;
    z-index: 35;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .devise-input {
    font-size: 16px;
  }

  /* styles for home page */

  .founders-container {
    flex-direction: column;
  }

  #colibri-clients-regular {
    display: none !important;
  }

  #colibri-clients-responsive {
    display: block !important;
  }

  .colibri-home-centered-container {
    padding-left: 12px;
    padding-right: 12px;
  }

  .bloglike-subsection-image-container {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;
    text-align: center;
  }

  #telework-image {
    width: 100%;
  }

  #presentation-text {
    font-size: 1.5em;
  }

  #colibri-nav-container {
    display: none;
  }

  #colibri-nav-right-list {
    display: none;
  }

  #colibri-mobile-nav {
    display: block !important;
    margin-right: 20px;
  }

  #colibri-mobile-menu {
    border-top: 2px solid #ccc;
    position: fixed;
    top: 53px;
    height: calc(100vh - 50px);
    width: 100vw;
    z-index: 30;
    background-color: white;
    padding: 15px;
  }

  .menu-item {
    border-bottom: 2px dotted #ccc;
  }

  .menu-item-title {
    display: block;
    padding: 10px;
    width: 100%;
    font-size: 1.5em;
    font-weight: 350;
  }

  .menu-sub-item {
    margin-left: 20px;
    padding: 4px;
  }

  .colibri-security-head {
    flex-direction: column;
  }

  #close-nav-mobile {
    font-size: 3.5em;
    padding-bottom: 7px;
    line-height: 53px;
    height: 53px;
  }

  #more-menu-items-list {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
  }

  #more-planning-menu-items {
    bottom: 70px;
    right: 12px;
  }

  .planning-menu-items.more-items {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .colibri-home-presentation-title.smartphone-centered {
    text-align: center;
  }

  #colibri-issues {
    flex-direction: column;
    margin: 0;
  }

  #issues-subsection {
    background-color: #f3f7fe;
    margin-top: 15px;
  }

  #colibri-issues-parent {
    margin-top: 20px;
  }

  .story-container {
    max-width: none;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }

  .home-subsection-detailed-content {
    flex-direction: column;
  }

  .home-subsection-content {
    padding-top: 0px;
  }

  .detailed-content-container {
    margin: 30px 0 0 0;
  }

  #blog-link-container {
    display: none;
  }

  #pages-nav-right {
    display: flex;
    justify-content: center;
  }

  img#colibri-logo {
    height: 50px;
    margin-left: 3px;
    margin-top: 3px;
    margin-right: -8px;
  }

  #nav-contact-button {
    display: none;
  }

  #presentation-text {
    width: 100%;
    padding-top: 0;
  }

  #colibri-home-pink-pattern,
  #colibri-home-pink-pattern-5 {
    display: none;
  }

  #colibri-home-pink-pattern-smartphone-landscape {
    display: none;
  }

  #colibri-home-pink-pattern-smartphone {
    z-index: 0;
    display: block !important;
    width: 100%;
  }

  #colibri-home-pink-pattern-smartphone.top-image {
    margin-top: -330px;
  }

  #colibri-home-pink-pattern-smartphone.pink-pattern-reporting {
    margin-top: -450px;
  }

  #colibri-home-pink-pattern-smartphone.pink-pattern-service-planning {
    margin-top: -500px;
  }

  #customer-stories-bg {
    display: none;
  }

  #service-planning-top-image {
    max-height: 55vh;
  }

  #colibri-home-pink-pattern-smartphone.pink-pattern-calendar {
    margin-top: -450px;
  }

  .iphone-vertical-image {
    max-height: 550px !important;
  }

  #main-smartphone-image {
    height: 550px;
    width: auto !important;
    margin-left: auto;
    margin-right: auto;
  }

  #main-smartphone-image.calendar-smartphone {
    height: 500px;
  }

  #calendar-main-image {
    display: none !important;
  }

  #calendar-tablet-image {
    display: flex !important;
    max-height: 500px;
  }

  #calendar-tablet-image.calendar-responsive-image  {
    display: flex !important;
  }

  .colibri-arguments-wrapper {
    max-width: none;
    padding-left: 15px;
    padding-right: 15px;
  }

  .colibri-argument-container {
    margin-bottom: 30px;
  }

  .colibri-argument-container.three-items,
  .colibri-argument-container.four-items {
    width: 100%;
  }

  #colibri-features-container {
    justify-content: center;
  }

  .colibri-feature-icon {
    margin-left: auto;
    margin-right: auto;
  }
  .colibri-feature-title {
    font-size: 1.1em;
    font-weight: 550;
  }
  .colibri-feature-container {
    width: 80%;
  }

  .add-margin-left-md {
    margin-left: 0;
  }

  #presentation-images-banner {
    z-index: 3;
    margin-top: 40px;
    height: 550px;
  }

  #top-subsection {
    display: block;
    margin-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
    z-index: 3;
    max-height: none;
    height: 1000px !important;
  }

  #top-subsection.product-top-section.handicap-top-section {
    height: auto !important;
  }

  #top-subsection.product-top-section {
    background-image: none;
  }

  #service-types-section {
    z-index: 3;
  }

  #main-image-responsive {
    display: block !important;
    z-index: 0;
  }

  .smartphone-portrait-only {
    display: block !important;
  }

  .all-but-smartphone-portrait {
    display: none !important;
  }

  #main-image {
    display: none !important;
  }

  .margin-md {
    margin: 0px;
  }

  #colibri-issues-head {
    margin-bottom: 20px;
  }

  .issue-container {
    margin: 10px;
    margin-bottom: 20px;
    max-width: none;
    width: calc(100% - 20px);
  }

  #presentation-images-banner {
    width: 100%;
    margin-bottom: 40px;
    z-index: 0;
    display: flex;
    justify-content: center;
  }

  #colibri-presentation-text {
    font-size: 0.7em;
  }

  #presentation-text {
    z-index: 5;
    display: block !important;
  }

  .colibri-home-presentation-title {
    font-size: 1.6em;
    line-height: 1.6em;
  }

  .colibri-home-subsection {
    padding: 50px 20px 50px 20px;
  }

  #shift-issue-details {
    margin-top: 15px;
    width: 100%;
    max-width: none;
  }

  .colibri-subsection-content-container {
    flex-direction: column;
    margin: 0;
  }

  .colibri-subsection {
    padding: 0;
    margin-bottom: 45px;
  }

  #heroku-and-salesforce {
    flex-direction: column;
  }

  #mobile-only-contact-form-button {
    display: block;
  }

  #mobile-menu-call-to-action {
    font-size: 1.3em;
  }

  #colibri-security-text {
    width: 100%;
  }

  #security-icon-container {
    width: 100%;
  }

  footer {
    background-color: #1b263b;
    color: white;
    padding: 35px 20px 80px 20px;
  }

  #colibri-footer {
    padding-top: 20px;
    flex-direction: column-reverse;
  }

  #footer-brand-container {
    width: 100%;
    max-width: none;
    margin-top: 40px;
  }

  .footer-colibri-information-title {
    font-size: 1.7em;
  }

  #footer-colibri-information-container {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    margin-left: 17px;
    font-size: 1.2em;
  }

  .footer-section {
    margin-bottom: 30px;
  }

  .footer-section-container {
    margin-top: 10px !important;
    margin-bottom: 15px;
  }

  /* styles for contact page */
  #contact-form-background {
    height: auto;
    padding: 70px 10px 50px 10px;
    flex-direction: column;
  }

  #contact-form-image-container {
    width: 60%;
  }

  #contact-form-login-button {
    display: none;
  }

  #thank-you-container {
    flex-direction: column;
    align-items: center;
  }

  #thank-you-container > div {
    margin-bottom: 25px;
  }

  /* styles for login page */

  .colibri-devise-form {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 90px;
    margin-bottom: 40px;
    background-color: #fff;
    padding: 20px 20px 20px 20px;
  }

  /* styles for navigation bar */
  #header-navigation {
    display: none;
  }

  .main-page {
    padding-top: 0px !important;
  }

  /* general app-like styles */

  ::-webkit-scrollbar {
    display: none !important;
  }

  .turbolinks-progress-bar {
    visibility: hidden;
  }

  .btn.btn-phone-expand {
    width: 100%;
  }

  .btn.btn-desktop {
    display: none;
  }

  .phone_activity_container {
    width: 100vw;
    height: 100vh;
    position: relative;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: white;
    overflow: scroll;
  }

  .activity_header {
    height: 48px;
    background-color: #2b384e;
    color: white;
    display: flex;
    padding-left: 12px;
    padding-right: 12px;
  }

  .activity_back_button {
    line-height: 48px;
  }

  .activity_back_icon {
    line-height: 48px;
    margin-right: 12px;
  }

  .activity_title {
    font-size: 1.3em;
    line-height: 48px;
  }

  .activity_body {
    height: calc(100vh - 48px);
    background-color: white;
  }

  .coli-report-container {
    margin-bottom: 6px;
  }

  .coli-report-header {
    display: flex;
    justify-content: space-between;
  }

  .coli-report-publisher {
    font-size: 0.85em;
  }

  .coli-report-published-at {
    font-size: 0.85em;
  }

  .coli-report-body {
    background-color: #defcfc;
    padding: 3px 8px 3px 8px;
    border-radius: 3px;
  }

  /* styles for planning left menu */

  #planning-menu-container {
    height: 75px;
    width: 100%;
    position: fixed;
    top: auto;
    bottom: 0 !important;
    left: 0;
    background-color: #fdfdfd;
    border-top: 0.5px solid #ccc;
    padding-top: 0;
    z-index: 15;
  }

  #planning-menu-list {
    height: 75px;
  }

  ul#left-menu-ul {
    height: 60px;
    margin-bottom: 15px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  ul#left-menu-ul.align-left {
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: row;
    align-items: start !important;
    justify-content: left !important;
  }

  ul#left-menu-ul.align-left > li.planning-menu-items {
    margin-left: 20px;
    margin-right: 20px;
  }

  .glyphicon-default {
    color: rgb(180, 190, 199);
  }

  .glyphicon-selected {
    color: rgb(115, 120, 124);
  }

  li.planning-menu-items {
    color: rgb(180, 190, 199);
    padding-top: 0px;
    width: 48px;
    text-align: center;
    margin-top: 0px;
  }

  span.coli-bottom-menu-icon {
    line-height: 48px;
  }

  .nav-tabs {
    border-bottom: 0px !important;
  }

  ul.nav-tabs {
    list-style: none;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0;
    padding-top: 0;
  }

  li.planning-menu-items {
    width: 48px !important;
    padding-bottom: 0;
    display: block;
    float: left !important;
    width: auto;
  }

  li.planning-menu-items:hover {
    color: rgb(115, 120, 124);
  }

  .glyphicon.glyphicon-cog:hover,
  .glyphicon.glyphicon-home:hover,
  .glyphicon.glyphicon-calendar:hover {
    color: rgb(115, 120, 124) !important;
  }

  /* styles dashboard and other content container */

  #colibri-dashboard-container {
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 3px;
    margin-bottom: 80px;
  }

  .colibri-dashboard-big-container {
    height: auto;
    flex-direction: column-reverse;
  }

  .colibri-dashboard-container-column-large {
    width: 100%;
    height: 140vh;
  }

  .colibri-dashboard-container-column-narrow {
    width: 100%;
    height: 70vh;
  }

  #planning-page-wrapper {
    left: 0 !important;
    width: 100vw !important;
  }

  #resource-list-container {
    background-color: #415465 !important;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    min-width: 150px;
    width: 10vw;
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    overflow-x: hidden;
    padding-top: 3px;
    padding-bottom: 0px;
    display: none;
  }

  #detail-container {
    display: block;
    position: absolute;
    width: 100%;
    max-width: none;
    top: 0;
    left: 0;
    margin: 0px;
    margin-bottom: 150px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 1px;
  }

  p.setting-title {
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 48px;
    color: white;
    font-size: 1.3em;
    font-weight: normal;
  }

  #planning-container {
    margin: 5px;
    padding-left: 0px;
    padding-right: 0px;
    width: calc(100% - 10px);
    height: calc(100vh - 92px - 75px);
  }

  #planning-container.office-container,
  #planning-container.smaller-top-margin {
    height: calc(100vh - 92px - 45px) !important;
  }

  .fc-view-harness.fc-view-harness-passive {
    border-top: 0.5px solid rgb(221, 221, 221);
    border-bottom: 0.5px solid rgb(221, 221, 221);
  }

  button.fc-today-button.fc-button.fc-button-primary.toggle-today-button {
    display: none !important;
  }

  #detail-planning-container {
    width: 100%;
    margin: 92px 0px 130px 0px;
    padding-top: 3px;
  }

  #detail-planning-container.smaller-top-margin,
  #detail-planning-container.office-planning-container {
    margin: 52px 0px 120px 0px !important;
  }

  #completion-reports-container {
    margin-left: 0px;
    margin-bottom: 200px;
    margin-top: 10px;
  }

  #payable-container {
    left: 0;
    top: 0;
    width: 100%;
    max-width: 100%;
    margin: 0px 0px 120px 0px;
    padding: 0px;
    padding-top: 50px;
  }

  /* Hide batch action button */

  #colibri-batch-action-button {
    height: 60px;
    width: 60px;
    border-radius: 60px;
  }

  .batch-action-button {
    bottom: 80px;
    right: 20px;
  }

  span.glyphicon.glyphicon-pencil.batch-action-pencil {
    line-height: 45px;
    font-size: 1.2em;
    margin-bottom: -5px;
  }

  /* Hide print button */
  #print-button {
    display: none;
  }

  /* Other planning and master related styles */

  #planning-top-right-action-container {
    line-height: 48px;
    margin-bottom: 0px;
    padding-top: 5px;
    vertical-align: middle;
    display: none;
  }

  /* Fullcalendar custom smartphone layouts */

  .fc-zoomOut-button,
  .fc-zoomIn-button {
    display: none !important;
  }

  .fc-scroller-harness > .fc-scroller {
    overflow-x: scroll !important;
    height: auto !important;
  }

  table.fc-list-table {
    border: none;
  }

  tr.fc-list-heading {
    border: none;
    border-bottom: 1px solid #ccc;
  }

  tr.fc-list-heading > td.fc-widget-header {
    background-color: white;
    text-align: center;
    border: none;
  }

  .fc-list-heading-main {
    font-size: 1.3em;
    margin-top: 5px;
    text-align: center;
  }

  .colibri-fc-list-header {
    margin: 0;
    display: flex;
    justify-content: space-between;
    color: black;
    font-size: 1.1em;
  }

  .colibri-fc-list-title {
    font-size: 1.2em;
    line-height: 1.2em;
  }

  .fc-view.fc-listDay-view.fc-list-view {
    border: none;
  }

  div.colibri-fc-list {
    margin: 4px 0px 4px 0px;
    padding: 4px 12px 7px 12px;
    border-radius: 3px !important;
    color: black;
  }

  .responsive-landscape-only {
    display: none;
  }

  /* Calendar view layouts */

  #planning-header {
    position: fixed;
    top: 0px;
    z-index: 9;
    width: 100vw;
    display: block;
    background-color: #2b384e;
    border-bottom: 0.3px solid #2b384e;
    padding-left: 0px;
  }

  #colibri-horizontal-menu {
    display: none;
  }

  #planning-header-container {
    display: block;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  #planning-header-submenu {
    display: flex;
    height: 38px;
    line-height: 38px;
  }

  #phone-reflect-button-wrapper {
    bottom: 75px;
  }

  .selectize-input {
    white-space: nowrap;
  }

  .header-submenu-item {
    text-align: center;
    flex-grow: 1;
    font-size: 1em;
    color: rgba(255, 255, 255, 0.5);
  }

  .header-submenu-item.header-submenu-item-selected {
    color: white;
    border-bottom: 3px solid white;
  }

  button#resource-details-button {
    display: none !important;
  }

  div#new-reminder-email {
    display: none !important;
  }

  #resource-name-wrapper {
    padding-right: 5px;
    padding-top: 0px;
    padding-left: 6px;
    margin-bottom: 5px;
    font-size: 1.3em;
    line-height: 48px;
    color: white;
    font-weight: normal;
  }

  #resource-search-icon {
    display: inline;
  }

  #patient-dashboard {
    display: block;
    height: auto;
  }

  .patient-dashboard-panel {
    width: 100%;
    margin-bottom: 5px;
    height: auto;
  }

  #toggle-patients-nurses-wrapper {
    margin-left: 3px;
  }

  #close-resource-list {
    display: block;
    text-align: right;
    font-size: 1.2em;
    color: white;
    margin-right: 6px;
  }

  #toggle-patients-nurses-wrapper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  #menu-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    height: 100vh;
    width: 100vw;
    background-color: black;
    opacity: 0.6;
  }

  #resource-list-container {
    height: 100vh;
  }

  #resource-container {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 70px;
  }

  #nurses-resource {
    padding-bottom: 70px;
  }

  #patients-resource {
    padding-bottom: 70px;
  }

  #planning-nurse-information {
    display: flex;
    color: white;
  }

  #resource-details-panel {
    width: 100%;
    height: calc(100vh - 150px);
    overflow-y: scroll;
    position: absolute;
    bottom: 55px;
    z-index: 11;
    border: 0px;
  }

  #resource-details-wrapper {
    width: 100%;
  }

  #resource-detail-body {
    height: auto;
  }

  #resource-detail-header {
    display: none;
  }

  #close-reports-index {
    display: none;
  }

  #display-completion-reports {
    display: none !important;
  }

  /* payable view styles */

  #appointments-by-category-detail {
    display: none;
  }

  #payable-menu {
    display: block;
  }

  .patient-left-panel {
    width: 100%;
  }

  .patient-right-panel {
    display: none;
  }

  #payable-query {
    display: block;
    margin-top: 10px;
  }

  .patient-dashboard-panel-body {
    height: auto;
  }

  .payable-body {
    padding: 5px;
  }

  #payable-title-wrapper {
    position: fixed;
    top: 0px;
    height: 48px;
    width: 100vw;
    z-index: 9;
    background-color: #2b384e;
    line-height: 48px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .colibri-xs-title.colibri-payable-title {
    font-size: 1.3em;
    font-weight: normal;
    color: white;
    line-height: 48px;
  }

  #detail-planning-title {
    margin: 0px;
    justify-content: space-between;
    height: 48px;
    line-height: 48px;
    padding-left: 12px;
    padding-right: 12px;
  }

  #nurse_filter_wrapper {
    margin: 5px;
    min-width: 0px !important;
  }

  #nurse_filter_wrapper > .selectize-control {
    width: auto;
  }

  #payable-title {
    margin-top: 5px;
  }

  #payable-query {
    padding-left: 12px;
  }

  .planning-activity-module-title {
    padding-left: 4px;
  }

  .forty-percent-width {
    width: calc(100% - 10px);
  }

  .sixty-percent-width {
    width: calc(100% - 10px);
  }

  .colibri-dashboard-container-row {
    overflow-x: auto;
    height: auto;
    flex-direction: column;
  }

  #posts-body {
    margin-top: 42px;
    padding-bottom: 60px;
  }

  .post-container {
    margin-bottom: 10px;
  }

  /* Settings view layouts */

  #patients-excel {
    display: none;
  }

  .hamburger_menu {
    display: inline-block;
    margin-right: 6px;
  }

  #logout {
    display: inline-block;
  }

  #settings-menu-container {
    height: 100vh;
    width: 200px;
    position: fixed;
    z-index: 30;
    top: 0px;
    left: 0px;
    padding-top: 10px;
    background-color: #415465 !important;
    border: 0.5px solid #415465;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    display: none;
  }

  #settings-container {
    margin: 0px 0px 0px 0px;
    padding: 0px;
    left: 0px !important;
    width: 100% !important;
  }

  .settings-body {
    margin-top: 5px;
    padding: 8px;
    margin-bottom: 60px;
  }

  .settings-body.with-mb-top-margin,
  #settings-container.with-mb-top-margin {
    margin-top: 52px;
  }

  #planning-options {
    width: 100%;
    max-width: none;
    margin-bottom: 30px;
  }

  #settings-menu-list {
    margin-top: 0px;
  }

  #index-table-container {
    margin-top: 10px;
  }

  .edit_corporation {
    margin-bottom: 70px;
  }

  #printing-options {
    max-width: none;
    width: 100%;
  }

  .edit_printing_option {
    margin-bottom: 70px;
  }

  .patients-submenu {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  }

  #detail-planning-container {
    height: auto;
    overflow-y: visible;
  }

  /* Forms layouts */

  #appointment_starts_at_4i:before {
    content: "\A";
  }

  .colibri-datetime-input-container {
    flex-direction: column;
  }

  #toggle-detailed-minutes-container {
    margin-top: 10px;
  }

  .colibri-datetime-separator {
    display: none;
  }

  .colibri-date-input {
    margin-bottom: 4px;
  }

  .btn-margin-bottom {
    margin-bottom: 3px;
  }

  /* Completion Reports layouts */

  #patient-completion-reports-body {
    margin-top: 40px;
    margin-bottom: 90px;
  }

  #handicap-image-container {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 25px;
    padding-bottom: 20px;
  }

  #handicap-image-container.smartphone-pink-bg {
    background: linear-gradient(170deg, #ffffff 50%, #fce7ea 50%);
  }

  #handicap-image-container.group-home-container {
    margin-top: 0px;
    padding-bottom: 40px;
    padding-top: 40px;
    background: linear-gradient(170deg, #ffffff 35%, #fce7ea 35%);
  }

  .reaction {
    min-width: 21px;
  }
}
