tr.colibri-fc-hover-highlight:hover {
  background-color: #7AD5DE;
  opacity: 20%;
}

tr.colibri-fc-hover-highlight {
  z-index: 4;
}

.fc-col-header-cell.fc-day-today {
    font-weight: bold;
    color: black;
}

.fc-daygrid-day.fc-day-today > .fc-daygrid-day-frame > .fc-daygrid-day-top {
  font-weight: bold;
  color: black;
}

.fc-event-title {
    font-size: 1.6em;
    line-height: 1.2;
}

.fc-day.fc-day-sat {
    color: #118df0;
}

.fc-day.fc-day-sun {
    color: #ff304f;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
    margin-bottom: 10px;
}

.fc-event.fc-bg-event.colibri-bg-green {
    background: linear-gradient(90deg, white 1px, transparent 1%) center, linear-gradient(white 1px, transparent 1%) center, #67DCA4;
    background: -webkit-linear-gradient(90deg, white 1px, transparent 1%) center, linear-gradient(white 1px, transparent 1%) center, #67DCA4;
    background: -moz-linear-gradient(90deg, white 1px, transparent 1%) center, linear-gradient(white 1px, transparent 1%) center, #67DCA4;
    background-size: 3px 3px !important;
}

.fc-event.fc-bg-event.colibri-bg-yellow {
    background: linear-gradient(90deg, white 1px, transparent 1%) center, linear-gradient(white 1px, transparent 1%) center, #FFDA62;
    background: -webkit-linear-gradient(90deg, white 1px, transparent 1%) center, linear-gradient(white 1px, transparent 1%) center, #FFDA62;
    background: -moz-linear-gradient(90deg, white 1px, transparent 1%) center, linear-gradient(white 1px, transparent 1%) center, #FFDA62;
    background-size:  3px 3px !important;
}

.fc-event.fc-bg-event.colibri-bg-red {
    background: linear-gradient(90deg, white 1px, transparent 1%) center, linear-gradient(white 1px, transparent 1%) center, #FD9BA6;
    background: -webkit-linear-gradient(90deg, white 1px, transparent 1%) center, linear-gradient(white 1px, transparent 1%) center, #FD9BA6;
    background: -moz-linear-gradient(90deg, white 1px, transparent 1%) center, linear-gradient(white 1px, transparent 1%) center, #FD9BA6;
    background-size: 3px 3px !important;
}

.fc-timegrid-body {
  width: 100%;
}

/* daygrid month view */

.fc-daygrid-block-event.fc-event > .fc-event-main > .fc-event-main-frame {
  flex-direction: column;
}

.fc-daygrid-block-event.fc-event > .fc-event-main > .fc-event-main-frame > .fc-colibri-header > .fc-event-time {
  font-size: 0.9em;
  padding-bottom: 0px;
  font-weight: normal;
}

.fc-event-time {
  line-height: 12px;
}

.fc-daygrid-block-event.fc-event > .fc-event-main > .fc-event-main-frame > .fc-event-title-container {
  margin-bottom: 1px;
}

/* fc timeline view */

.fc-timeline-event > .fc-event-main > .fc-event-main-frame {
  flex-direction: column;
}

.fc-event > .fc-event-main > .fc-event-main-frame {
  overflow-x: hidden !important;
}


.fc-timeline-event.fc-event > .fc-event-main > .fc-event-main-frame > .fc-colibri-header > .fc-event-time {
  font-size: 0.85em;
  padding-bottom: 0px;
  font-weight: normal;
}

.fc-timeline-event.fc-event {
  display: flex;
  margin:1px;
  border-radius: 3px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  align-items: start !important;
}

h2.fc-toolbar-title {
  line-height: 40px;
}

button.fc-button {
  margin-bottom:2px !important;
  max-height:42px !important;
}

/* non-business hours */

div.fc-non-business {
  background: rgba(228, 228, 228, 0.3) !important;
}

.filter-selected.fc-button.fc-button-primary {
  background-color: #d5d5d5 !important;
}


@media print {

  .fc-scroller.fc-scroller-liquid-absolute, .fc-scroller,
  .fc-day-grid-container,
  .fc-time-grid-container,.fc-scroller-harness, .fc-view, .fc-view-harness.fc-view-harness-active {
    /* */
    height: auto !important;
    overflow: visible !important;
  }

  .fc-timegrid > .fc-scrollgrid {
    height: auto;
  }

  .fc-timegrid-slots > table {
    height: auto !important;
  }

  .fc-scroller, .fc-timegrid-body {
    height: auto !important;
    display: flex;
  }

  .fc-scrollgrid.fc-scrollgrid-liquid {
    border: none;
  }

  .fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid {
    overflow: visible !important;
  }

  /* kill the horizontal border/padding used to compensate for scrollbars */
  .fc-row {
    border: 0 !important;
    margin: 0 !important;
  }

  ::-webkit-scrollbar {
    width: 0px;
  }

  /* do not hide background events */
  .fc-event.fc-bg-event {
    -webkit-print-color-adjust: exact !important;
  }

  /* Make background events more visible */

  .fc-event.colibri-bg-green {
    background: linear-gradient(90deg, white 2px, transparent 1%) center, linear-gradient(white 2px, transparent 1%) center, #67DCA4;
    background: -webkit-linear-gradient(90deg, white 2px, transparent 1%) center, linear-gradient(white 2px, transparent 1%) center, #67DCA4;
    background: -moz-linear-gradient(90deg, white 2px, transparent 1%) center, linear-gradient(white 2px, transparent 1%) center, #67DCA4;
    background-size: 5px 5px !important;
  }

  .fc-event.colibri-bg-yellow {
    background: linear-gradient(90deg, white 2px, transparent 1%) center, linear-gradient(white 2px, transparent 1%) center, #FFDA62;
    background: -webkit-linear-gradient(90deg, white 2px, transparent 1%) center, linear-gradient(white 2px, transparent 1%) center, #FFDA62;
    background: -moz-linear-gradient(90deg, white 2px, transparent 1%) center, linear-gradient(white 2px, transparent 1%) center, #FFDA62;
    background-size:  5px 5px !important;
  }

  .fc-event.colibri-bg-red {
    background: linear-gradient(90deg, white 2px, transparent 1%) center, linear-gradient(white 2px, transparent 1%) center, #FD9BA6;
    background: -webkit-linear-gradient(90deg, white 2px, transparent 1%) center, linear-gradient(white 2px, transparent 1%) center, #FD9BA6;
    background: -moz-linear-gradient(90deg, white 2px, transparent 1%) center, linear-gradient(white 2px, transparent 1%) center, #FD9BA6;
    background-size: 5px 5px !important;
  }


  /* fill cancelled and edit requested appointments */

  .fc-event.colibri-edit-requested {
    background-image: repeating-linear-gradient(45deg, #C8F6DF, #C8F6DF 5px, #99E6BF 5px, #99E6BF 10px) !important;
    -webkit-print-color-adjust: exact;
  }
    
  .fc-event.colibri-cancelled {
    background-image: repeating-linear-gradient(45deg, #FFBFBF, #FFBFBF 5px, #FF8484 5px, #FF8484 10px) !important;
    -webkit-print-color-adjust: exact;
  }

  .fc-event.colibri-missing-report {
    color: rgba(0,0,0,0.7);
    background-image: repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(255,255,255,0.35) 5px, rgba(255,255,255,0.35) 10px) !important;
    -webkit-print-color-adjust: exact;
  }

      /* Button Controls
  --------------------------------------------------------------------------------------------------*/
  .fc-button-group, button.fc-button {
    display: none !important;
    /* don't display any button-related controls */
  }

  /* hide now indicator */
  .fc-timegrid-now-indicator-arrow, .fc-timegrid-now-indicator-line {
    display: none;
  }


  /* Undo month-view event limiting. Display all events and hide the "more" links
  --------------------------------------------------------------------------------------------------*/

	.fc-more-cell,
	.fc-more {
		display: none !important;
	}

	.fc tr.fc-limited {
		display: table-row !important;
	}

	.fc td.fc-limited {
		display: table-cell !important;
  }
  
  div.fc-event-title {
    font-weight: bold !important;
    font-size: 17px !important;
    color: black;
  }

  .fc-timeline-event.fc-event > .fc-event-main > .fc-event-main-frame > .fc-event-title-container > .fc-event-title {
    font-size: 18px !important;
    font-weight: normal !important;
    color: black; 
  }

	.fc-popover {
		display: none;
		/* never display the "more.." popover in print mode */
  }


  /* custom Colibri styles 
  -------------------------------------------------------------------------------------------------*/

  .fc-timegrid-slot.fc-timegrid-slot-label {
    font-weight: bold;
  }

  /* more visible dates */
  th.fc-col-header-cell {
    font-weight: bold;
    font-size: 1.35em;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  /* making horizontal lines thicker for 9:00 12:00 15:00 18:00 */
  .thick-calendar-line {
    border-top: 3px solid #ccc !important;
  }

  /* make event borders thicker */
  .fc-day-grid-event, .fc-time-grid-event, .fc-v-event, .fc-event, .fc-start, .fc-end, .fc-draggable, .fc-resizable {
    border-width: 4.5px !important;
  }
  
  .fc-timeline-event.fc-event {
    border-width: 2px !important;
  }  

}

:root {
  --fc-neutral-bg-color: white;
}