
#toggle-appointment-and-completion-report {
    display:flex;
    justify-content:space-around;
    font-size:1.4em;
    color:#697580;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
}

.appointment-menu-toggle-item {
    padding-left: 20px;
    padding-right: 20px;
}

.appointment-menu-toggle-item:hover {
    cursor:pointer;
    color: #4F5B66;
    border-bottom:3px solid #828E99;
}

.appointment-menu-toggle-item.item-selected {
    border-bottom: 3px solid #7AD5DE;
}

.calendar-draggable {
    display:flex;
    margin: 8px 0px 0px 0px;
    padding:8px 7px 8px 7px;
    font-size: 0.95em;
    border-radius:4px;
    background-color:#EFF2F5;
}

.calendar-draggable:hover {
    cursor: grab;
}

td.edit-requested-appointment-row > td {
    padding-top:8px;
    padding-bottom:8px;
}


#resource-details-wrapper.mitei-wrapper,#resource-details-wrapper.mitei-wrapper > #resource-details-block {
    height:100%;
}

#draggable-container {
    height: calc(100% - 32px);
    overflow-y: auto;
}

#transportation-form-body {
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
    flex-direction:column;
}

#color-select-container {
    width: 230px;
}