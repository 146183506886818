@media screen and (orientation: landscape) and (min-width: 900px) {
	/* layout only for desktop */
	#detail-container.no-resources-width {
		max-width: calc(100vw - 55px);
		width: calc(100vw - 55px);
	}

	#payable-container.no-resources-width {
		width: calc(100vw - 58px);
		left: 58px;
	}

    .fc-list-day-cushion.fc-cell-shaded {
        display: none;
    }

    .fc-list-day.fc-day.colibri-listday-no-events {
        display:none;
    }

    .fc-list-empty, .fc.fc-list-empty {
        background-color: white !important;
    }

    .fc-scroller.fc-scroller-liquid {
        background-color:white;
    }

    .fc-event.fc-list-event:hover {
        cursor: pointer;
    }
}