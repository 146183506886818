.colibri-input {
    border: 1px solid rgb(190, 189, 189);
    border-radius: 4px;
    padding: 1px 4px 1px 4px;
    text-align: right;
}

.colibri-input.input-text-left {
    text-align: left;
}

.colibri-input.additional-padding {
    padding: 3px 6px 3px 6px;
}